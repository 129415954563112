import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Tariff} from '../../../../services/tariff.service';
import {Contract} from '../../../../services/person.service';

@Component({
  selector: 'app-contract-documents-description',
  templateUrl: './contract-documents-description.component.html',
  styleUrls: ['./contract-documents-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractDocumentsDescriptionComponent implements OnChanges {

  @Input()
  contractForm: UntypedFormGroup = null;
  @Input()
  contract: Contract = null;
  @Input()
  tariffs: Tariff[];
  @Input()
  showBranch = true;
  tariff: Tariff;

  getContractTarif(): number {
    return this.contractForm ? this.contractForm.get('tarif').value : this.contract.tarif;
  }

  getBeginlaufzeit(): Date {
    return this.contractForm ? this.contractForm.get('beginlaufzeit').value : this.contract.beginlaufzeit;
  }

  ngOnChanges() {
    if ((this.contract || this.contractForm) && this.tariffs) {
      this.tariff = this.tariffs.find(t => t.id === this.getContractTarif());
    }
  }

  branchInfo() {
    return this.showBranch ? ` - ${this.tariff.vertragart.sparte.bezeichnung}/${this.tariff.vertragart.bezeichnung}` : ''
  }
}
