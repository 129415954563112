// tslint:disable:max-line-length
import {Injectable} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {Step, StepSlug} from '../step-definitions';
import {TaskDefinition, Variables} from '../../shared/common/services/task.service';
import {
  BasicDataComparisonComponent
} from '../components/data-comparison/customer-basic-data/basic-data-comparison.component';
import {
  CustomerContactDataComparisonComponent
} from '../components/data-comparison/customer-contact-data/contact-data-comparison.component';
import {ContactAddressComponent} from '../components/data-comparison/customer-adresses/contact-address.component';
import {CustomerBankComponent} from '../components/data-comparison/customer-bank/customer-bank.component';
import {CustomerContractComponent} from '../components/data-comparison/customer-contract/customer-contract.component';
import {
  CustomerContractSingleComponent
} from '../components/data-comparison/customer-contract-single/customer-contract-single.component';
import {CustomerIdentityComponent} from '../components/data-comparison/customer-identity/customer-identity.component';
import {
  CustomerEmploymentsComponent
} from '../components/data-comparison/customer-employments/customer-employments.component';
import {SurplusComponent} from '../components/data-comparison/customer-surplus/surplus.component';
import {
  DocumentsOverviewComponent
} from '../components/data-comparison/documents-overview/documents-overview.component';
import {TaskReworksComponent} from '../components/data-comparison/task-reworks/task-reworks.component';
import {
  BrokerContractComponent
} from '../components/data-comparison/customer-broker-contract/broker-contract.component';
import {
  CustomerMaritalStatusComponent
} from '../components/data-comparison/customer-marital-status/customer-marital-status.component';
import {TariffService} from './tariff.service';
import {TaxInfosComponent} from '../components/data-comparison/tax-infos/tax-infos.component';
import {
  CustomerRelationshipsComponent
} from '../components/data-comparison/customer-relationships/customer-relationships.component';
import {AppOptionsService} from './app-options.service';
import {Contact, Person, Status} from './person.service';

// tslint:enable:max-line-length

@Injectable()
export class WizardFormService {
  constructor(readonly fb: UntypedFormBuilder, readonly options: AppOptionsService, readonly tariffService: TariffService) {
  }

  public buildStepGroup(step: Step, taskDefinition: TaskDefinition, variables: Variables, customer?: Person) {

    if (customer && variables.version === '1') {
      variables = this.mergeVariables(variables, customer);
    }
    switch (step.slug) {
      case StepSlug.CUSTOMER_BASIC:
        return BasicDataComparisonComponent.buildGroup(this.fb, variables);
      case StepSlug.CUSTOMER_CONTACT:
        return CustomerContactDataComparisonComponent.buildGroup(this.fb, variables, this.options.contactTypes);
      case StepSlug.CUSTOMER_ADDRESSES:
        return ContactAddressComponent.buildGroup(this.fb, variables, customer);
      case StepSlug.CUSTOMER_BANK:
        return CustomerBankComponent.buildGroup(this.fb, variables, customer);
      case StepSlug.CUSTOMER_CONTRACT:
        return CustomerContractComponent.buildGroup(this.fb, taskDefinition, variables, this.tariffService.tariffs);
      case StepSlug.CUSTOMER_CONTRACT_SINGLE:
        return CustomerContractSingleComponent.buildGroup(this.fb, taskDefinition, variables, this.tariffService.tariffs);
      case StepSlug.CUSTOMER_IDENTITY:
        return CustomerIdentityComponent.buildGroup(this.fb, variables);
      case StepSlug.CUSTOMER_EMPLOYMENTS:
        return CustomerEmploymentsComponent.buildGroup(this.fb, variables);
      case StepSlug.CUSTOMER_TAX_INFOS:
        return TaxInfosComponent.buildGroup(this.fb, variables);
      case StepSlug.CUSTOMER_SURPLUS:
        return SurplusComponent.buildGroup(this.fb, variables);
      case StepSlug.DOCUMENTS_OVERVIEW:
        return DocumentsOverviewComponent.buildGroup(this.fb, taskDefinition, variables);
      case StepSlug.TASK_REWORK_OVERVIEW:
        return TaskReworksComponent.buildGroup(this.fb, variables);
      case StepSlug.CUSTOMER_BROKER_CONTRACT:
        return BrokerContractComponent.buildGroup(this.fb, taskDefinition, variables, this.options.brokerContractOptions);
      case StepSlug.CUSTOMER_RELATIONSHIPS:
        return CustomerRelationshipsComponent.buildGroup(this.fb, taskDefinition, variables);
      case StepSlug.CUSTOMER_MARITAL_STATUS:
        return CustomerMaritalStatusComponent.buildGroup(this.fb, variables);
      default:
        throw new Error(`Cannot build form Group for Step '${step.slug}'`);
    }
  }

  public mergeVariables(variables: Variables, customer: Person): Variables {
    const variablesCustomer = variables.customer as Person;

    const kommunikationsdaten: Contact[] = this.mergeArrayProperty('kommunikationsdaten', variablesCustomer, customer);
    // special handling for contact data primary fields
    const primaryEmails = kommunikationsdaten.filter(entry => (entry.typ === 1 || entry.typ === 11) && entry.vorrang);
    if (primaryEmails.length > 1) {
      primaryEmails
        .filter(entry => !!entry.id)
        .forEach(entry => entry.vorrang = false);
    }

    const primaryOthers = kommunikationsdaten.filter(entry => entry.vorrang && entry.typ !== 1 && entry.typ !== 11);
    if (primaryOthers.length > 1) {
      primaryOthers
        .filter(entry => !!entry.id)
        .forEach(entry => entry.vorrang = false);
    }
    return {
      ...variables,
      customer: {
        ...variablesCustomer,
        adressen: this.mergeArrayProperty('adressen', variablesCustomer, customer, ['postanschrift', 'meldeanschrift']),
        ueberschuss: this.mergeArrayProperty('ueberschuss', variablesCustomer, customer),
        bankverbindungen: this.mergeArrayProperty('bankverbindungen',  variablesCustomer, customer, ['hauptverbindung']),
        beschaeftigungsverhaeltnisse: this.mergeArrayProperty('beschaeftigungsverhaeltnisse', variablesCustomer, customer, ['primaer']),
        kommunikationsdaten,
        identifikationsmerkmale: this.mergeArrayProperty('identifikationsmerkmale',  variablesCustomer, customer),
        beziehungen: this.mergeArrayProperty('beziehungen', variablesCustomer, customer),
        beziehungenVon: this.mergeArrayProperty('beziehungenVon', variablesCustomer, customer),
        familienstandHistorie: this.mergeArrayProperty('familienstandHistorie', variablesCustomer, customer)
      }
    };
  }

  private mergeArrayProperty(property: string, variablesCustomer: Person,
                             customer: Person,
                             primaryFields: string[] = null): any[] {
    // look for deleted entries
    const result: any[] = variablesCustomer[property]
      .reduce((prev, va) => {
        if (va.id > 0) {
          const notFound = !customer[property].find(a => a.id === va.id);
          if (va.status === Status.MODIFIED && notFound) {
            // mark as new and remove status
            return [...prev, {
              ...va,
              id: undefined,
              status: undefined
            }];
          } else if ((!va.status || va.status === Status.DELETED) && notFound) {
            return prev;
          }
        }
        return [...prev, va];
      }, []);

    // add new entries and update unmodified once
    customer[property].forEach(entry => {
      const index = result.findIndex(a => a.id === entry.id);
      if (index !== -1) {
        const variableEntry = result[index];
        if (!variableEntry.status) {
          result[index] = {...entry};
        }
      } else {
        result.push({...entry});
      }
    });

    // look for duplicate primary entries - new ones always win
    if (primaryFields) {
      primaryFields.forEach(primaryField => {
        const primaries = result.filter(entry => entry[primaryField] === true);
        if (primaries.length > 1) {
          primaries
            .filter(entry => !!entry.id)
            .forEach(entry => entry[primaryField] = false);
        }
      });
    }

    return result;
  }
}
