import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {Person} from '../../services/person.service';

@Component({
  selector: 'app-transfer-customer',
  templateUrl: './transfer-customer.component.html',
  styleUrls: ['./transfer-customer.component.scss']
})
export class TransferCustomerComponent implements OnInit {

  customerControl: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<TransferCustomerComponent>,
              private dialog: MatDialog,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.customerControl = this.fb.control(null, Validators.required);
  }

  finishDialog() {
    if (this.customerControl.valid) {
      const customerId = this.customerControl.value;
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          cancel: 'canceled',
          confirm: 'confirmed',
          text: 'Der Vorgang wird gelöscht und für den ausgewählten Kunden neu angelegt. Alle Änderungen gehen verloren.'
        }
      }).afterClosed().subscribe((result) => {
        if (result === 'confirmed') {
          this.dialogRef.close({variables: {customerId}});
        }
      });
    }
  }

  onNewCustomer(customer: Person) {
    this.customerControl.setValue(customer.id);
  }
}
