import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateCustomerDialogComponent} from '../create-customer-dialog/create-customer-dialog.component';
import {CustomerService} from '../../services/customer.service';
import {Address, Person} from "../../services/person.service";

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCustomerComponent {
  @ViewChild('fileInput', {static: true})
  fileInput: ElementRef;

  /**
   * Optional address that is used when creating the customer
   */
  @Input()
  addresses?: Address[];
  @Input()
  name = {firstName: '', lastName: ''};

  @Output()
  newCustomer = new EventEmitter<Person>();
  loading = false;

  constructor(readonly dialog: MatDialog,
              readonly customerService: CustomerService,
              readonly changeDetector: ChangeDetectorRef) {
  }

  createPerson(): void {
    this.dialog.open(CreateCustomerDialogComponent, {
      data: {
        addresses: this.addresses,
        name: this.name
      },
      width: '400px'
    })
      .afterClosed()
      .subscribe(result => {
        if (result && result.id) {
          this.newCustomer.emit(result);
        }
      });
  }
}
