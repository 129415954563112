import {Pipe, PipeTransform} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Status} from "./services/person.service";

@Pipe({name: 'notDeleted', pure: false})
export class NotDeletedPipe implements PipeTransform {
  transform(groups: UntypedFormGroup[]) {
    return groups.filter(group => {
      const status = group.get('status');
      return !status || status.value !== Status.DELETED;
    });
  }
}
