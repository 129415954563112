import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Component, Input, OnDestroy} from '@angular/core';
import {SingleMaritalStatusComponent} from './single-marital-status/single-marital-status.component';
import {Observable, of, Subscription} from 'rxjs';
import {isNil} from 'lodash-es';
import * as moment from 'moment';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService} from '../../../services/app-options.service';
import {Person, Status} from '../../../services/person.service';

export const MARITAL_STATUS_SINGLE_KEY = 'led';

@Component({
  selector: 'app-customer-marital-status-comparison',
  templateUrl: 'customer-marital-status.component.html',
  styleUrls: ['customer-marital-status.component.scss']
})
export class CustomerMaritalStatusComponent implements OnDestroy {
  customer: UntypedFormGroup;
  familienstandHistorie: UntypedFormArray;
  maritalStatiTypes: Observable<{ [key: string]: string }>;
  private variablesGroup: UntypedFormGroup;
  private subscription: Subscription;

  constructor(private fb: UntypedFormBuilder, private options: AppOptionsService) {
    this.maritalStatiTypes = of(this.options.maritalStatusTypes);
  }

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.customer = (this.variables.controls.customer as UntypedFormGroup);
    this.familienstandHistorie = (this.customer.controls.familienstandHistorie as UntypedFormArray);
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
    this.sortCardsByDate();
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const martitalStatus = fb.array([]);
    const customer = variables.customer as Person;

    if (customer && customer.familienstandHistorie) {
      customer.familienstandHistorie
        .forEach(m => martitalStatus.push(
          SingleMaritalStatusComponent.buildGroup(fb, m, m.status !== Status.DELETED)
        ));
    }

    // initialize if List is Empty
    if (martitalStatus.length === 0) {
      martitalStatus.push(SingleMaritalStatusComponent.buildGroup(fb, {
        familienstand: MARITAL_STATUS_SINGLE_KEY,
        gueltigAb: customer.geburtsdatum
      }));

      if (!isNil(customer.familienstand) && customer.familienstand !== MARITAL_STATUS_SINGLE_KEY) {
        martitalStatus.push(SingleMaritalStatusComponent.buildGroup(fb, {
          familienstand: customer.familienstand
        }));
      }
    }

    return fb.group({
      customer: fb.group({
        familienstandHistorie: martitalStatus
      })
    });
  }

  ngOnDestroy(): void {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }

  addCard() {
    this.familienstandHistorie.insert(0, SingleMaritalStatusComponent.buildGroup(this.fb, {gueltigAb: new Date()}, true));
  }

  removeCard(i: number) {
    const entity = this.familienstandHistorie.at(i);
    if (entity.get('id').value) {
      entity.patchValue({status: Status.DELETED}, {emitEvent: false});
    } else {
      this.familienstandHistorie.removeAt(i);
    }
  }

  sortCardsByDate() {
    if (this.familienstandHistorie.valid) {
      this.familienstandHistorie.controls
        .sort((a, b) => {
          const controlA = a.get('gueltigAb');
          const controlB = b.get('gueltigAb');
          return moment(controlA.value).isBefore(moment(controlB.value)) ? 1 : -1;
        });
    }
  }
}
