import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DocumentService} from '../../services/document.service';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {TauresValidators} from '../../taures-validators';
import {FileDocumentPreviewPresenter} from '../document-file-input/document-file-input.component';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {AppOptionsService} from '../../services/app-options.service';
import {ContractDocument, CustomerDocument} from '../../services/person.service';

type DocumentTypeOption = {
  label: string
  value: string
}

@Component({
  selector: 'app-document-form',
  templateUrl: 'document-form.component.html',
  styleUrls: ['document-form.component.scss']
})
export class DocumentFormComponent implements OnInit, OnDestroy {
  @Input()
  documentTypes: { [key: string]: string };
  @Output()
  documentChange = new EventEmitter<CustomerDocument | ContractDocument>();
  @Input()
  previewPresenter: FileDocumentPreviewPresenter;
  @Input()
  preview = false;
  file: UntypedFormControl = new UntypedFormControl();
  private destroy = new Subject();
  today = new Date();
  filteredTypes: Observable<DocumentTypeOption[]>;
  typeFilterCtrl: UntypedFormControl = new UntypedFormControl();

  constructor(@Inject(LOCALE_ID) private locale: string,
              readonly documentService: DocumentService,
              readonly options: AppOptionsService,
              readonly changeDetectorRef: ChangeDetectorRef) {
  }

  private formGroup: UntypedFormGroup;

  get group() {
    return this.formGroup;
  }

  @Input()
  set group(value: UntypedFormGroup) {
    this.formGroup = value;

    // copy the values and the validator in our private form control
    const fileIdControl = value.get('fileId');
    const id = value.get('id').value;
    this.file.setValidators(fileIdControl.validator);
    this.file.reset({id, fileId: fileIdControl.value, filename: value.get('filename').value}, {emitEvent: false});
  }

  public static buildGroup(fb: UntypedFormBuilder, document: ContractDocument | CustomerDocument, withValidation = true, disabled = false) {
    const validators = withValidation && !disabled ? [Validators.required] : [];
    const formGroup = fb.group({
      id: document.id,
      typ: [document.typ, validators],
      kommentar: document.kommentar,
      fileId: [document.fileId, validators],
      filename: [document.filename, validators],
      versendet: document.versendet,
      versendetAn: document.versendetAn
    });
    if ((document as any).currentProcess) {
      formGroup.addControl('currentProcess', fb.control((document as any).currentProcess));
    }
    if (disabled) {
      formGroup.disable();
    }
    return formGroup;
  }

  ngOnInit() {
    this.group.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => this.documentChange.emit(value));

    const typControl = this.group.get('typ');
    const validators = [TauresValidators.isDocumentType(Object.keys(this.options.documentTypes))];
    if (typControl.validator) {
      validators.push(typControl.validator);
    }
    typControl.setValidators(validators);
    this.filteredTypes = this.typeFilterCtrl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filterTypes(filterString)),
        map(filteredDocumentTypesMap => this.toDocumentTypeOptions(filteredDocumentTypesMap)),
        map(filteredDocumentTypes => this.sort(filteredDocumentTypes)),
      );

    this.file.valueChanges
      .pipe(
        takeUntil(this.destroy),
        switchMap(file => {
          if (!file) {
            return of(null);
          }
          return this.documentService
            .guessDocumentType(file.filename)
            .pipe(map(type => ({type, file})));
        }))
      .subscribe(fileAndType => {
        this.group.patchValue({
          fileId: fileAndType ? fileAndType.file.fileId : null,
          filename: fileAndType ? fileAndType.file.filename : null,
          typ: fileAndType && fileAndType.type && !typControl.value ? fileAndType.type : typControl.value
        });
      });
  }


  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }

  private sort(types: DocumentTypeOption[]): DocumentTypeOption[] {
    return types.sort((a, b) => a.label.localeCompare(b.label, this.locale))
      .reduce((listCopy, entry) => {
        listCopy.push({label: entry.label, value: entry.value})
        return listCopy
      }, new Array<DocumentTypeOption>());
  }

  private filterTypes(value: string): any {
    const filterValue = value.toLowerCase();
    return Object.entries(this.documentTypes)
      .filter(entry => entry[0].toLocaleLowerCase().includes(filterValue) || entry[1].toLocaleLowerCase().includes(filterValue))
      .reduce((obj, entry) => {
        obj[entry[0]] = entry[1];
        return obj;
      }, {});
  }

  showVerschicktFields() {
    const typ = this.group.get('typ').value;
    return typ === 'ant' || typ === 'san' || typ === 'vot' || typ === 'vaf' || typ === 'nbe';
  }

  onDateChange($event: MatDatepickerInputEvent<Date>) {
    this.group.controls.versendet.setValue($event.value);
    this.changeDetectorRef.markForCheck();
  }

  private toDocumentTypeOptions(documentTypesMap: { [key: string]: string }): DocumentTypeOption[] {
    return Object.entries(documentTypesMap)
      .map(([key, value]) => ({
        value: key,
        label: value
      }));
  }
}
