import {Component, Input, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Task} from '../../../shared/common/services/task.service';
import {Tariff, TariffService} from '../../services/tariff.service';
import {Tariffs} from '../../tariffs';
import {Contract, Person, PersonService} from '../../services/person.service';

const CONTRACT_ID_KEY = 'contractId';

@Component({
  selector: 'app-follow-up-backoffice-details',
  templateUrl: './follow-up-backoffice-details.component.html',
  styleUrls: ['./follow-up-backoffice-details.component.scss']
})
export class FollowUpBackofficeDetailsComponent implements OnInit {

  customer: Observable<Person>;
  tariffs: Observable<Tariff[]>;
  taskContract: Contract;

  constructor(readonly tariffService: TariffService, readonly personService: PersonService) {
  }

  private currentTask: Task;

  get task() {
    return this.currentTask;
  }

  @Input()
  set task(value: Task | null) {
    this.currentTask = value;
    if (this.task) {
      if (this.task.variables) {
        // only request the customer if we not already have her in the variables data
        const customer = this.task.variables.customer as Person;
        if (customer) {
          this.customer = of(customer);

          const contractId = this.task.variables[CONTRACT_ID_KEY];
          this.taskContract = customer.vertraege.find(c => c.id === contractId);
        } else {
          const customerId = this.task.variables.customerId;
          if (customerId) {
            this.customer = this.personService.getPerson(customerId);
          }
        }
      }
    }
  }

  getInsurer(tariffs: Tariff[]) {
    return Tariffs.insurerName(tariffs, this.taskContract.tarif);
  }

  getSparte(tariffs: Tariff[]) {
    return Tariffs.sparteName(tariffs, this.taskContract.tarif);
  }

  ngOnInit() {
    this.tariffs = of(this.tariffService.tariffs);
  }
}
