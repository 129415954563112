import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {TauresValidators} from '../../../taures-validators';
import {ContactComponent} from './contact/contact.component';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService, ContactType} from '../../../services/app-options.service';
import {Person, Status} from '../../../services/person.service';
import {EMAIL_GROUP, PHONE_GROUP} from '../../../services/customer.service';

@Component({
  selector: 'app-customer-contact-comparison',
  templateUrl: 'contact-data-comparison.component.html',
  styleUrls: ['contact-data-comparison.component.scss']
})
export class CustomerContactDataComparisonComponent {
  contactTypes: ContactType[] = [];
  customerGroup: UntypedFormGroup;
  contactDataArray: UntypedFormArray;
  phoneGroup = PHONE_GROUP;
  emailGroup = EMAIL_GROUP;

  constructor(private fb: UntypedFormBuilder, readonly options: AppOptionsService) {
    this.contactTypes = options.contactTypes;
  }

  private variablesGroup: UntypedFormGroup;

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(controlGroup: UntypedFormGroup) {
    this.variablesGroup = controlGroup;
    if (this.variables) {
      this.customerGroup = (this.variables.controls.customer as UntypedFormGroup);
      this.contactDataArray = (this.customerGroup.controls.kommunikationsdaten as UntypedFormArray);
    }
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables, contactTypes: ContactType[]) {
    const customer = variables.customer as Person;
    const contactData = fb.array([], TauresValidators.oneContactGroupRequired(contactTypes, ['EMAIL', 'MOBIL', 'FESTNETZ']));

    if (customer && customer.kommunikationsdaten) {
      // move primary to the top
      customer.kommunikationsdaten
        .filter(contact => !contact.vorrang)
        .forEach(contact =>
          contactData.push(ContactComponent.buildGroup(fb, contact, contactTypes,
            contact.status !== Status.DELETED)));
      customer.kommunikationsdaten
        .filter(contact => contact.vorrang === true)
        .forEach(contact => contactData.insert(0,
          ContactComponent.buildGroup(fb, contact, contactTypes, contact.status !== Status.DELETED)));
    }

    return fb.group({
      customer: fb.group({
        kommunikationsdaten: contactData
      })
    });
  }

  addEmail() {
    const emailType = this.contactTypes.find(ct => ct.gruppe === EMAIL_GROUP[0]);
    let j = 0;
    let count = 0;
    while (j < this.contactDataArray.length) {
      const control = this.contactDataArray.at(j) as UntypedFormGroup;
      const contactType = this.getContactType(control);
      const status = control.get('status');
      if ((!status || status.value !== Status.DELETED) && EMAIL_GROUP.indexOf(contactType.gruppe) !== -1) {
        count++;
      }
      j++;
    }
    const newEmail = {id: null, typ: emailType.id, vorrang: count === 0};
    this.contactDataArray.push(ContactComponent.buildGroup(this.fb, newEmail, this.contactTypes));
  }

  addTelNum() {
    const telType = this.contactTypes.find(ct => ct.gruppe === PHONE_GROUP[0]);
    let j = 0;
    let count = 0;
    while (j < this.contactDataArray.length) {
      const control = this.contactDataArray.at(j) as UntypedFormGroup;
      const contactType = this.getContactType(control);
      const status = control.get('status');
      if ((!status || status.value !== Status.DELETED) && PHONE_GROUP.indexOf(contactType.gruppe) !== -1) {
        count++;
      }
      j++;
    }
    const newTelNum = {id: null, typ: telType.id, vorrang: count === 0};
    this.contactDataArray.push(ContactComponent.buildGroup(this.fb, newTelNum, this.contactTypes));
  }

  removeContact(group: UntypedFormGroup) {
    let index = -1;
    while (index < this.contactDataArray.length) {
      const c = this.contactDataArray.at(index);
      if (c === group) {
        break;
      }
      index++;
    }
    const entity = this.contactDataArray.at(index);
    const wasPrime = entity.get('vorrang').value;
    if (entity.get('id').value) {
      entity.patchValue({status: Status.DELETED, vorrang: false}, {emitEvent: false});
    } else {
      this.contactDataArray.removeAt(index);
    }
    if (wasPrime) {
      let j = 0;
      while (j < this.contactDataArray.length) {
        const c = this.contactDataArray.at(j);
        const status = c.get('status');
        if (this.isOfSameType(group, c as UntypedFormGroup) && (!status || status.value !== Status.DELETED)) {
          c.patchValue({vorrang: true});
          break;
        }
        j++;
      }
    }
  }

  setContactToPrime(group: UntypedFormGroup) {
    let index = -1;
    let j = 0;
    while (j < this.contactDataArray.length) {
      const c = this.contactDataArray.at(j);
      if (c === group) {
        index = j;
      }
      if (this.isOfSameType(group, c as UntypedFormGroup)) {
        c.patchValue({vorrang: false});
      }
      j++;
    }
    group.patchValue({vorrang: true});
    this.contactDataArray.removeAt(index);
    this.contactDataArray.insert(0, group);
  }

  private isOfSameType(first: UntypedFormGroup, second: UntypedFormGroup) {
    const firstContactType = this.getContactType(first).gruppe;
    const secondContactType = this.getContactType(second).gruppe;
    const isEmail = EMAIL_GROUP.indexOf(firstContactType) !== -1 && EMAIL_GROUP.indexOf(secondContactType) !== -1;
    const isPhone = PHONE_GROUP.indexOf(firstContactType) !== -1 && PHONE_GROUP.indexOf(secondContactType) !== -1;
    return isEmail || isPhone;
  }

  private getContactType(group: UntypedFormGroup) {
    return this.contactTypes.find(ct => ct.id === group.controls.typ.value);
  }

  public hasNoPhoneContact(): boolean {
    return !this.contactDataArray.controls
      .filter(contact => contact.get('status').value !== Status.DELETED)
      .some((contact: UntypedFormGroup) => PHONE_GROUP.indexOf(this.getContactType(contact).gruppe) !== -1);
  }
}
