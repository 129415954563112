import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IdentityType} from '../../../../services/app-options.service';
import {IdentificationFeature, Status} from '../../../../services/person.service';
import {generateId} from "../../../../id-helper";

@Component({
  selector: 'app-single-identity',
  templateUrl: 'single-identity.component.html',
  styleUrls: ['single-identity.component.scss']
})

export class SingleIdentityComponent implements OnDestroy {
  @Output() deleteIdentity: EventEmitter<void> = new EventEmitter();
  @Input()
  identityTypes: IdentityType[];
  private destroy: Subject<boolean> = new Subject();

  private group: UntypedFormGroup;

  get control(): UntypedFormGroup {
    return this.group;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.group = value;
    if (this.group.get('status') && this.isFromServer) {
      this.group.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.group.get('status').setValue(Status.MODIFIED, {emitEvent: false});
        });
    }
  }

  get isFromServer(): boolean {
    return !!this.group.get('id').value && this.group.get('id').value > 0;
  }

  public static buildGroup(fb: UntypedFormBuilder, identity: IdentificationFeature, validate: boolean = true) {
    const isFromServer = !!identity.id && identity.id > 0;

    const ausweisartValidators = [];
    const nummerValidators = [];
    const behoerdeValidators = [];
    const gueltigAbValidators = [];

    if (!isFromServer) {
      ausweisartValidators.push(Validators.required);
      nummerValidators.push(Validators.required);
      behoerdeValidators.push(Validators.required);
      gueltigAbValidators.push(Validators.required);
    }

    return fb.group({
      id: identity.id ?? generateId(),
      status: identity.status,
      ausweisart: [{value: identity.ausweisart, disabled: isFromServer}, validate ? ausweisartValidators : []],
      nummer: [{value: identity.nummer, disabled: isFromServer}, validate ? nummerValidators : []],
      behoerde: [{value: identity.behoerde, disabled: isFromServer}, validate ? behoerdeValidators : []],
      gueltigBis: [{value: identity.gueltigBis, disabled: isFromServer}, []],
      ausstellungsdatum: [{value: identity.ausstellungsdatum, disabled: isFromServer}, validate ? gueltigAbValidators : []],
      dokument: identity.dokument
    });
  }

  deleteCard() {
    this.deleteIdentity.emit();
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
