<div class="flex gap-2 justify-content-between p-2 {{headerClasses}}">
  <div>{{title}}</div>
  <div>{{crmEntity ? 'Bestand' : 'Neu'}}</div>
</div>
<div class="flex flex-column flex-grow-1 p-4">
  <div class="flex-grow-1">
    <ng-content select="[tr-data-comparison-card-content]"></ng-content>
  </div>
  <div>
    <ng-content select="[tr-data-comparison-card-buttons]"></ng-content>
  </div>
</div>
