import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {Tariff} from '../../../../services/tariff.service';
import {MatSelectChange} from '@angular/material/select';
import {FileDocumentPreviewPresenter} from '../../../document-file-input/document-file-input.component';
import {ContractDocument, CustomerDocument} from '../../../../services/person.service';

@Component({
  selector: 'app-document-card',
  templateUrl: './document-card.component.html',
  styleUrls: ['./document-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentCardComponent {

  @Input()
  documentArray: UntypedFormArray;

  @Input()
  documentGroup: UntypedFormGroup;

  @Input()
  moveTargetForms: UntypedFormArray[];

  @Input()
  moveTargetGroup: UntypedFormGroup;

  @Input()
  documentTypes: { [key: string]: string };

  @Input()
  sameTypeCount = 0;

  @Input()
  tariffs: Tariff[];

  @Input()
  previewPresenter: FileDocumentPreviewPresenter;

  @Output() removeDocument = new EventEmitter<void>();
  @Output() moveDocument = new EventEmitter<MatSelectChange>();
  @Output() documentAdded = new EventEmitter<Document>();
  @Output() documentChange = new EventEmitter<CustomerDocument | ContractDocument>();

  onRemoveDocument() {
    const customerDocumentIndex = this.documentArray.controls.indexOf(this.documentGroup);
    this.documentArray.removeAt(customerDocumentIndex);
    this.removeDocument.emit();
  }

  get showSameTypeHint() {
    return this.sameTypeCount > 1 && !this.documentGroup.enabled;
  }
}
