import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as wizard from '../actions/wizard.actions';
import {
  COMPLETE_WIZARD,
  COMPLETE_WIZARD_FAILURE,
  COMPLETE_WIZARD_SUCCESS,
  CompleteWizardAction,
  CompleteWizardFailureAction,
  CompleteWizardSuccessAction,
  GOTO_STEP,
  GotoStepAction,
  NEXT_STEP,
  PREVIOUS_STEP,
  START_WIZARD,
  START_WIZARD_SUCCESS,
  StartWizardAction,
  StartWizardFailureAction,
  StartWizardSuccesAction
} from '../actions/wizard.actions';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {Task, TaskDefinition, TaskService, Variables} from '../../shared/common/services/task.service';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import * as RouterActions from '../actions/router.actions';
import {MatSnackBar} from '@angular/material/snack-bar';
import {WizardCompleteSuccessComponent} from '../components/wizard-complete-success/wizard-complete-success.component';
import {OpenConfirmDialogAction} from '../actions/inbox';
import {CUSTOMER_BASIC} from '../step-definitions';
import {PersonService} from '../services/person.service';

@Injectable()
export class WizardEffects {

  keepUrlInSync = createEffect(() => this.actions
    .pipe(
      ofType<wizard.Actions>(START_WIZARD_SUCCESS, NEXT_STEP, PREVIOUS_STEP, GOTO_STEP),
      withLatestFrom(this.store.pipe(select(fromRoot.getWizardState))),
      map(([action, state]) => {
        let step = state.currentStep;
        if (action instanceof GotoStepAction) {
          step = action.payload.step;
        }
        return new RouterActions.Go({path: ['/wizard', step.slug, state.task.id]});
      })
    ));

  loadCustomerOfTask = createEffect(() => this.actions
    .pipe(
      ofType<StartWizardAction>(START_WIZARD),
      map(action => action.payload),
      mergeMap(payload => {
          if (payload.task.variables && payload.task.variables.customerId) {
            return this.personService.getPerson(payload.task.variables.customerId, true).pipe(
              map(customer => new StartWizardSuccesAction({...payload, customer})),
              catchError(() => of(new StartWizardFailureAction()))
            );
          } else {
            return of(new StartWizardSuccesAction(payload));
          }
        }
      )
    ));

  completeTask = createEffect(() => this.actions
    .pipe(
      ofType<CompleteWizardAction>(COMPLETE_WIZARD),
      map(action => action.payload),
      withLatestFrom(this.store.pipe(select(fromRoot.getWizardTask))),
      switchMap(([payload, task]: [Variables, Task]) =>
        this.taskService.completeTask(task.id, payload)
          .pipe(
            switchMap((processes: Task[]) => {
              if (processes && processes.length > 0) {
                return this.taskService.claimTask(processes[0].id).pipe(
                  switchMap(() => this.taskService.getTaskVariables(processes[0].id).pipe(
                    switchMap((variables) => {
                      return [
                        new StartWizardAction({task: {...processes[0], variables}, step: CUSTOMER_BASIC}),
                        new RouterActions.Go({path: ['/wizard/customer-basic/' + processes[0].id]}),
                        new CompleteWizardSuccessAction(task)
                      ];
                    })
                  )),
                  catchError(error => {
                    this.snackBar.open('Aufgabe wird bereits bearbeitet.', null, {duration: 5000});
                    return EMPTY;
                  })
                );
              } else {
                return [
                  new RouterActions.Go({path: ['/']}),
                  new CompleteWizardSuccessAction(task)
                ];
              }
            }),
            catchError((err) => of(new CompleteWizardFailureAction(err)))
          )
      )
    ));

  completeWizardSuccessDialog = createEffect(() => this.actions
    .pipe(
      ofType<CompleteWizardSuccessAction>(COMPLETE_WIZARD_SUCCESS),
      map(action => action.payload),
      tap(task => {
        this.snackBar.openFromComponent(WizardCompleteSuccessComponent,
          {
            data: task,
            duration: 12000,
            panelClass: 'taures-light-snackbar',
            verticalPosition: 'top'
          });
      })
    ), {dispatch: false});

  completeWizardFailure409 = createEffect(() => this.actions
    .pipe(
      ofType<CompleteWizardFailureAction>(COMPLETE_WIZARD_FAILURE),
      map(action => action.payload),
      filter(error => error.status === 409),
      map(() => {
        return new OpenConfirmDialogAction({
          title: 'Aufgabe konnte nicht abgeschlossen werden',
          text: 'Die Aufgabe wurde in der Zwischenzeit durch einen anderen Benutzer bearbeitet. Die Änderungen werden nicht übernommen.',
          confirm: new RouterActions.Go({path: ['/']})
        });
      })
    ));

  constructor(private actions: Actions,
              private store: Store<fromRoot.State>,
              private taskService: TaskService,
              private personService: PersonService,
              private snackBar: MatSnackBar) {
  }
}
