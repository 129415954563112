import {Location} from '@angular/common';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {concat, of, timer} from 'rxjs';
import {catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {
  CLAIM_TASK,
  ClaimTaskAction,
  ClaimTaskFailureAction,
  CONFIRM_DELETE_TASK,
  CONFIRM_RESET_CONTRACT,
  ConfirmDeleteTaskAction,
  ConfirmResetContractAction,
  CREATE_NEW_PROCESS,
  CreateNewProcessAction,
  CreateNewProcessFailureAction,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DeleteTaskAction,
  DeleteTaskFailureAction,
  DeleteTaskSuccessAction,
  LOAD_TASK,
  LOAD_TASKS,
  LOADING_TASKS,
  LoadingTasksAction,
  LoadTaskAction,
  LoadTaskFailureAction,
  LoadTasksAction,
  LoadTasksFailureAction,
  LoadTasksSuccessAction,
  LoadTaskSuccessAction,
  OPEN_CONFIRM_DIALOG,
  OpenConfirmDialogAction,
  RESET_CONTRACT,
  ResetContractAction,
  ResetContractFailureAction,
  ResetContractSuccessAction,
} from '../actions/inbox';
import {
  COMMENT_TASK,
  CommentTaskAction,
  CommentTaskFailureAction,
  CONFIRM_UNCLAIM_TASK,
  ConfirmUnclaimTaskAction,
  StartWizardAction,
  UNCLAIM_TASK,
  UnclaimTaskAction,
  UnclaimTaskFailureAction,
  UnclaimTaskSuccessAction,
  UPDATE_TASK_VARIABLES,
  UpdateTaskVariablesAction,
  UpdateTaskVariablesFailureAction
} from '../actions/wizard.actions';
import * as RouterActions from '../actions/router.actions';

import {ConfirmDialogComponent} from '../components/confirm-dialog/confirm-dialog.component';
import * as fromRoot from '../reducers';
import {TaskService} from '../../shared/common/services/task.service';
import {PersonService} from '../services/person.service';

@Injectable()
export class InboxEffects {


  loadTasks = createEffect(() => this.actions
    .pipe(
      ofType<LoadTasksAction>(LOAD_TASKS),
      withLatestFrom(
        this.store.pipe(select(fromRoot.hasTasksLoading))
      ),
      filter(([action, loading]) => !loading),
      map(([action]) => new LoadingTasksAction(action.payload))
    ));

  /**
   * Loads all the tasks and keeps the browsers location in sync with the filter setting
   */
  loadingTasks = createEffect(() => this.actions
    .pipe(
      ofType<LoadingTasksAction>(LOADING_TASKS),
      withLatestFrom(this.store.pipe(select(fromRoot.getFilter))),
      mergeMap(([action, currentFilter]) => this.taskService.getTasks(action.payload || currentFilter)
        .pipe(
          tap(() => {
            this.location.go('', TaskService.filterToUrlParams(action.payload || currentFilter).toString());
          }),
          map(taskResponse => new LoadTasksSuccessAction(taskResponse)),
          catchError(() => of(new LoadTasksFailureAction()))
        )
      )
    ));

  loadSingleTask = createEffect(() => this.actions
    .pipe(
      ofType<LoadTaskAction>(LOAD_TASK),
      map(action => action.payload),
      mergeMap(taskId => this.taskService.getTask(taskId)
        .pipe(
          map(task => new LoadTaskSuccessAction(task)),
          catchError(() => of(new LoadTaskFailureAction()))
        )
      )
    ));

  /**
   * Creates a new Process. Before that it loads the customer and enhances the variables with it.
   * After that it starts the wizard for the new task
   */
  createProcess = createEffect(() => this.actions
    .pipe(
      ofType<CreateNewProcessAction>(CREATE_NEW_PROCESS),
      map(action => action.payload),
      switchMap(task => this.personService.getPerson(task.variables.customerId, true)
        .pipe(
          catchError(() => of(new CreateNewProcessFailureAction())),
          map(c => {
            const variables: any = {customer: c, version: '1'};
            if (task.variables.skipFollowUpAgent) {
              variables.skipFollowUpAgent = true;
            }
            return Object.assign(task, {variables});
          }),
          mergeMap(variables => this.taskService.createProcess(variables)
            .pipe(
              map(process => new StartWizardAction({task: process.tasks[0], step: task.step})),
              catchError(() => of(new CreateNewProcessFailureAction()))
            ))
        )
      )
    ));

  /**
   * Claims a task and starts the wizard for the task
   */
  claimTask = createEffect(() => this.actions
    .pipe(
      ofType<ClaimTaskAction>(CLAIM_TASK),
      map(action => action.payload),
      switchMap(task =>
        this.taskService.claimTask(task.id).pipe(
          map(() => new StartWizardAction({task})),
          catchError(() => of(new ClaimTaskFailureAction()))
        )
      )
    ));

  /**
   * Updates the variables of a given task and adds a comment if given.
   * Navigates back to the inbox
   */
  updateVariables = createEffect(() => this.actions
    .pipe(
      ofType<UpdateTaskVariablesAction>(UPDATE_TASK_VARIABLES),
      map(action => action.payload),
      switchMap(payload =>
        concat(
          this.taskService.saveTaskVariables(payload.taskId, payload.variables),
          this.taskService.addComment(payload.taskId, payload.comment)
        ).pipe(
          map(() => new RouterActions.Go({path: ['/']})),
          catchError(() => of(new UpdateTaskVariablesFailureAction()))
        )
      )
    ));

  confirmUnclaimTask = createEffect(() => this.actions
    .pipe(
      ofType<ConfirmUnclaimTaskAction>(CONFIRM_UNCLAIM_TASK),
      map(action => action.payload),
      map(payload => new OpenConfirmDialogAction({
        title: 'Aufgabe zurückgeben', text: 'Wollen Sie die Aufgabe wirklich zurückgeben?',
        confirm: new UnclaimTaskAction(payload)
      }))
    ));

  /**
   * Unclaims a given task and optionally adds a comment if given.
   * Navigates back to the inbox
   */
  unclaimTask = createEffect(() => this.actions
    .pipe(
      ofType<UnclaimTaskAction>(UNCLAIM_TASK),
      map(action => action.payload),
      switchMap(payload =>
        concat(
          this.taskService.unclaimTask(payload.taskId, payload.variables),
          this.taskService.addComment(payload.taskId, payload.comment)
        ).pipe(
          switchMap(() => [
              new UnclaimTaskSuccessAction(),
              new RouterActions.Go({path: ['/']}),
              new LoadTaskAction(payload.taskId)
            ]
          ),
          catchError(() => of(new UnclaimTaskFailureAction()))
        )
      )
    ));

  /**
   * Adds a comment for a task if given.
   * Navigates back to the inbox
   */
  commentTask = createEffect(() => this.actions
    .pipe(
      ofType<CommentTaskAction>(COMMENT_TASK),
      map(action => action.payload),
      switchMap(payload => this.taskService.addComment(payload.taskId, payload.comment)
        .pipe(
          map(() => new RouterActions.Go({path: ['/']})),
          catchError(() => of(new CommentTaskFailureAction()))
        )
      )
    ));

  openConfirmDialog = createEffect(() => this.actions
    .pipe(
      ofType<OpenConfirmDialogAction>(OPEN_CONFIRM_DIALOG),
      map(action => action.payload),
      switchMap(payload => timer(1) // we need a timer here, otherwise the dialog would interrupt the previous effect...
        .pipe(
          switchMap(() => this.matDialog.open(ConfirmDialogComponent, {data: payload})
            .beforeClosed()
            .pipe(switchMap(result => {
              if (!result) {
                return [];
              }
              if (result instanceof Array) {
                return result;
              }
              return of(result);
            }))))
      )));

  confirmDeleteTask = createEffect(() => this.actions
    .pipe(
      ofType<ConfirmDeleteTaskAction>(CONFIRM_DELETE_TASK),
      map(action => action.payload),
      map(payload => new OpenConfirmDialogAction({
        title: 'Aufgabe löschen', text: 'Wollen Sie die Aufgabe wirklich löschen? Alle Daten gehen verloren',
        confirm: new DeleteTaskAction(payload)
      }))
    ));

  deleteTask = createEffect(() => this.actions
    .pipe(
      ofType<DeleteTaskAction>(DELETE_TASK),
      map(action => action.payload),
      switchMap(task => this.taskService.deleteTask(task)
        .pipe(
          map(_ => task),
          catchError(() => of(null))
        )
      ),
      map(task => {
        if (task) {
          return new DeleteTaskSuccessAction(task);
        }
        return new DeleteTaskFailureAction();
      })
    ));

  openInboxOnDelete = createEffect(() => this.actions
    .pipe(
      ofType<DeleteTaskSuccessAction>(DELETE_TASK_SUCCESS),
      map(() => new RouterActions.Go({path: ['/']}))
    ));

  confirmResetContract = createEffect(() => this.actions
    .pipe(
      ofType<ConfirmResetContractAction>(CONFIRM_RESET_CONTRACT),
      map(action => action.payload),
      map(payload => new OpenConfirmDialogAction({
        title: 'Vertrag zurücksetzen',
        text: `Dieser Untervertrag wird zu einem eigenständigen Vertrag umgewandet.
        Alle Daten werden gespeichert. Dieser Vorgang kann nicht rückgängig gemacht werden`,
        confirm: new ResetContractAction(payload)
      }))
    ));

  resetContract = createEffect(() => this.actions
    .pipe(
      ofType<ResetContractAction>(RESET_CONTRACT),
      map(action => action.payload),
      switchMap(payload =>
        this.taskService.saveTaskVariables(payload.taskId, payload.variables)
          .pipe(
            mergeMap(() => this.taskService.resetContract(payload.taskId, payload.contractId)),
            mergeMap(() => [
              new RouterActions.Go({path: ['/']}),
              new ResetContractSuccessAction()]
            ),
            catchError(() => of(new ResetContractFailureAction()))
          )
      )
    ));


  constructor(private actions: Actions,
              private store: Store<fromRoot.State>,
              private taskService: TaskService,
              private personService: PersonService,
              private location: Location,
              private matDialog: MatDialog) {
  }
}
