import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Person} from '../../services/person.service';


@Component({
  selector: 'app-select-employer',
  templateUrl: 'select-employer.component.html',
  styleUrls: ['./select-employer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectEmployerComponent {
  customers: Person[];

  constructor(@Inject(MAT_DIALOG_DATA) private readonly data: Person[],
              private dialogRef: MatDialogRef<SelectEmployerComponent>) {
    this.customers = data;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSelect(customer: Person) {
    this.dialogRef.close(customer);
  }
}

