import {ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, LOCALE_ID, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Rework} from '../../../../services/rework';
import * as moment from 'moment';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ActionCategory, AppOptionsService, BusinessProcess} from '../../../../services/app-options.service';
import {Status} from '../../../../services/person.service';

@Component({
  selector: 'app-rework-form',
  templateUrl: 'rework-form.component.html',
  styleUrls: ['rework-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReworkFormComponent {
  @Output() deleteContact: EventEmitter<any> = new EventEmitter();
  @Input()
  control: UntypedFormGroup;
  actionCategoryTypes: ActionCategory[];
  actionStatusTypes: { [key: string]: string };
  actionStatusTypesOrder = ['170', '191', '189', '180', '190', '192', '186', '193'];

  constructor(@Inject(LOCALE_ID) private locale: string, readonly options: AppOptionsService, private store: Store<fromRoot.State>) {
    this.actionCategoryTypes = options.actionCategoryTypes;
    this.actionStatusTypes = options.actionStatusTypes;
  }

  get actionStatusTypesKeys() {
    return Object.keys(this.actionStatusTypes)
      .filter(key => this.actionStatusTypesOrder.includes(key))
      .sort((a: string, b: string) => {
        return this.actionStatusTypesOrder.indexOf(a) - this.actionStatusTypesOrder.indexOf(b);
      });
  }

  public static initGroup(fb: UntypedFormBuilder, rework: Rework, needsValidation = true, disabled = false) {
    const crmRework = rework.updateStatus === Status.MODIFIED;
    const isVertrag = rework.objekttyp === 'V';
    return fb.group({
      id: [{value: rework.id, disabled}, []],
      objekttyp: [{value: rework.objekttyp, disabled}, needsValidation ? [Validators.required] : null],
      objekt: [{value: rework.objekt, disabled}, crmRework && isVertrag ? [Validators.required] : []],
      gvKategorie: [{value: rework.gvKategorie, disabled}, needsValidation ? [Validators.required] : null],
      geschaeftsvorfall: [{
        value: rework.geschaeftsvorfall,
        disabled
      }, needsValidation ? [Validators.required] : null],
      status: [{value: rework.status, disabled}, needsValidation ? [Validators.required] : []],
      durchfuehrungSoll: [{
        value: rework.durchfuehrungSoll || moment().add(3, 'days').toDate(),
        disabled
      }, needsValidation ? [Validators.required] : null],
      erinnerungsdatum: [{value: rework.erinnerungsdatum, disabled}, []],
      infoAnBetreuer: [{value: rework.infoAnBetreuer, disabled}, []],
      beschreibung: [{value: rework.beschreibung, disabled}, needsValidation && !crmRework ? [Validators.required] : null],
      updateStatus: [{value: rework.updateStatus, disabled}, []],
      createDate: [{value: rework.createDate, disabled}, []],
      kommentare: [{value: rework.kommentare, disabled}, []]
    });
  }

  getBuisnessProcesses(dbvalue: string): BusinessProcess[] {
    const action = this.actionCategoryTypes ? this.actionCategoryTypes.find(a => a.dbvalue === dbvalue) : null;
    return action ? action.geschaeftsvorfaelleNeuanlage : [];
  }

  hasContractId(): Observable<any> {
    return this.store.pipe(select(fromRoot.getWizardTask)).pipe(
      map(task => task.variables.contractId)
    );
  }
}
