import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {SelectEmployerComponent} from '../select-employer/select-employer.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PlzLookupService} from '../../services/plz-lookup.service';
import {COUNTRY_CODES, GERMANY_COUNTRY_CODE} from '../../services/country-codes';
import {CustomerService} from '../../services/customer.service';
import {Person, PersonService} from "../../services/person.service";

const PHONE_PATTERN = /^([+0])\d+[\d \/-]*\d+$/;

@Component({
  selector: 'app-create-employer',
  styleUrls: ['create-employer.component.scss'],
  templateUrl: 'create-employer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateEmployerComponent implements OnDestroy {
  form: UntypedFormGroup;
  subscription: Subscription;
  plzSubscription: Subscription;
  landSubscription: Subscription;
  placeNamesSubscription: Subscription;
  placeNames: Observable<string[]>;
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  private destroy = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<CreateEmployerComponent>,
              private fb: UntypedFormBuilder,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private plzLookup: PlzLookupService,
              private personService: PersonService,
              private customerService: CustomerService) {


    this.form = this.fb.group({
      name: [null, [Validators.required]],
      adresse: this.fb.group(
        {
          strasse: [null, [Validators.required]],
          nummer: [null, [Validators.required]],
          plz: [null, [Validators.required]],
          stadt: [null, [Validators.required]],
          land: [GERMANY_COUNTRY_CODE, [Validators.required]],
          gueltigAb: [Date.now(), [Validators.required]],
          adresseco: [null, []],
          meldeanschrift: [false, [Validators.required]],
          postanschrift: [true, [Validators.required]],
          geschaeftsanschrift: [true, [Validators.required]]
        }
      ),
      telefon: [null, [Validators.pattern(PHONE_PATTERN)]],
      email: [null, [Validators.email]]
    });

    this.plzSubscription = this.form.get('adresse').get('plz').valueChanges.subscribe(newPlz => {
        this.getPlaceNames();
      }
    );
    this.landSubscription = this.form.get('adresse').get('land').valueChanges.subscribe(newPlz => {
        this.changeLand();
      }
    );
  }

  getPlaceNames() {
    const plz = this.form.get('adresse').get('plz');
    const country = this.form.get('adresse').get('land');

    if (plz.valid) {
      this.placeNames = this.plzLookup.getPlaceNames(plz.value, country.value);
      this.placeNamesSubscription = this.placeNames.subscribe(placeNames => {
        if (placeNames.length === 1) {
          this.form.get('adresse').get('stadt').setValue(placeNames[0]);
        }
      });
    } else {
      this.placeNames = new Observable<string[]>(observer => {
        observer.next([]);
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.plzSubscription) {
      this.plzSubscription.unsubscribe();
    }
    if (this.landSubscription) {
      this.landSubscription.unsubscribe();
    }
    if (this.placeNamesSubscription) {
      this.placeNamesSubscription.unsubscribe();
    }
    this.destroy.next();
    this.destroy.complete();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      const newEmployee = {
        name: this.form.get('name').value,
        adressen: [this.form.get('adresse').value],
        kommunikationsdaten: [
          {eintrag: this.form.get('telefon').value, typ: 4, vorrang: true} as any,
          {eintrag: this.form.get('email').value, typ: 11, vorrang: true} as any
        ]
      };
      this.personService
        .getPersons({lastName: newEmployee.name, companiesOnly: true})
        .pipe(
          takeUntil(this.destroy),
          mergeMap(persons => {
            if (!persons.length) {
              return this.customerService.createEmployer(newEmployee)
                .pipe(
                  catchError(error => {
                      if (error instanceof HttpErrorResponse && error.status === 409) {
                        const customer = error.error as Person;
                        return this.personService.getPerson(customer.kunde.betreuer)
                          .pipe(
                            map(consultant => throwError(`Diese Firma existiert schon im System. Die Firma wird von ${consultant.vorname} ${consultant.nachname} betreut.`)),
                            catchError(() => throwError('Konnte nicht angelegt werden.'))
                          );
                      }
                      return throwError('Konnte nicht angelegt werden.');
                    }
                  ));
            } else {
              return of(persons);
            }
          })
        )
        .subscribe(result => {
          // list of persons with the name
          if (Array.isArray(result)) {
            // open select of employee
            this.dialog.open(SelectEmployerComponent,
              {
                data: result
              })
              .afterClosed()
              .subscribe(selectedEmployee => {
                if (selectedEmployee) {
                  // if we have a customer with an id, just return that
                  if (selectedEmployee.id) {
                    this.dialogRef.close(selectedEmployee);
                  }
                }
                this.dialogRef.close();
              });
          } else if (result) {
            this.dialogRef.close(result);
          }
        }, error => {
          this.snackbar.open(error, 'OK');
          this.dialogRef.close();
        });
    }
  }

  displayCountry(code: string): string {
    const country = COUNTRY_CODES.find(c => c.code === code);
    return country ? country.name : '';
  }

  changeLand() {
    if (this.form.get('adresse').get('land').value === GERMANY_COUNTRY_CODE) {
      this.form.get('adresse').get('plz').setValidators(
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]*$/)
        ]
      );
    } else {
      this.form.get('adresse').get('plz').setValidators([Validators.required]);
    }
    this.form.get('adresse').get('plz').updateValueAndValidity();
    this.getPlaceNames();
  }

  private filter(src: { code: string, name: string }[], value): { code: string, name: string }[] {
    return src.filter(option =>
      option.name.toLowerCase().indexOf(value.toLowerCase()) === 0);
  }
}
