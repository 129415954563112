import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActionCategory, AppOptionsService, BusinessProcess} from '../../../../services/app-options.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Contract, Status} from '../../../../services/person.service';
import {Subject} from 'rxjs';
import {Tariff} from '../../../../services/tariff.service';

@Component({
  selector: 'app-rework-readonly',
  templateUrl: './rework-readonly.component.html',
  styleUrls: ['./rework-readonly.component.scss']
})
export class ReworkReadonlyComponent implements OnInit, OnDestroy {

  @Input()
  reworkForm: UntypedFormGroup;
  @Input()
  contracts: Contract[];
  @Input()
  tariffs: Tariff[];
  @Input()
  customerId: number;
  actionCategoryTypes: ActionCategory[];
  actionStatusTypes: { [key: string]: string };
  actionStatusTypesOrder = ['170', '173', '191', '189', '180', '190', '192', '186', '193'];
  private destroy = new Subject<void>();

  @Output()
  modifiedRework: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  constructor(readonly fb: UntypedFormBuilder, readonly options: AppOptionsService) {
    this.actionCategoryTypes = options.actionCategoryTypes;
    this.actionStatusTypes = options.actionStatusTypes;
  }

  get actionStatusTypesKeys() {
    return Object.keys(this.actionStatusTypes)
      .filter(key => this.actionStatusTypesOrder.includes(key))
      .sort((a: string, b: string) => {
        return this.actionStatusTypesOrder.indexOf(a) - this.actionStatusTypesOrder.indexOf(b);
      });
  }

  get status() {
    return this.actionStatusTypes[this.reworkForm.get('status').value];
  }

  get category() {
    const actionCategory = this.actionCategoryTypes.find(a => a.dbvalue === this.reworkForm.get('gvKategorie').value);
    return actionCategory ? actionCategory.uivalue : '';
  }

  get transactional() {
    const actionCategory = this.actionCategoryTypes.find(a => a.dbvalue === this.reworkForm.get('gvKategorie').value);
    if (!actionCategory) {
      return '';
    }
    const businessProcess = actionCategory.geschaeftsvorfaelle.find(g => g.dbvalue === this.reworkForm.get('geschaeftsvorfall').value);
    return businessProcess ? businessProcess.uivalue : '';
  }

  ngOnInit() {
    this.reworkForm.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(() => {
        if (this.reworkForm.get('updateStatus').value !== Status.MODIFIED) {
          this.reworkForm.get('updateStatus').setValue(Status.MODIFIED, {emitEvent: false});
          this.modifiedRework.emit(this.reworkForm);
        }
      });
    this.reworkForm.get('objekttyp').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(typ => {
        const objekt = this.reworkForm.get('objekt');
        if (typ === 'K') {
          objekt.setValue(this.customerId, {emitEvent: false});
          objekt.clearValidators();
        } else {
          objekt.setValue(null, {emitEvent: false});
          objekt.setValidators([Validators.required]);
        }
        objekt.updateValueAndValidity({emitEvent: false});
      });
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  getBuisnessProcesses(dbvalue: string): BusinessProcess[] {
    const action = this.actionCategoryTypes ? this.actionCategoryTypes.find(a => a.dbvalue === dbvalue) : null;
    return action ? action.geschaeftsvorfaelle : [];
  }
}
