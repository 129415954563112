import {ChangeDetectionStrategy, Component, Inject, OnDestroy} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import {AgentService, UserService} from '@taures/angular-commons';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CustomerService} from '../../services/customer.service';
import {TauresValidators} from '../../taures-validators';
import {Address, Person, PersonService, Status} from "../../services/person.service";

@Component({
  selector: 'app-create-customer-dialog',
  templateUrl: 'create-customer-dialog.component.html',
  styleUrls: ['create-customer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateCustomerDialogComponent implements OnDestroy {
  form: UntypedFormGroup;
  minBirthday: Date = new Date();
  maxBirthday: Date = new Date();
  includeConsultantFilter = {};
  betreuerFilter = {only34d: true, excludeInactive: true};
  loading = false;
  private destroy = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: { addresses?: Address[], name: { firstName: string, lastName: string } },
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<CreateCustomerDialogComponent>,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private agentService: AgentService,
    private personService: PersonService,
    private snackbar: MatSnackBar
  ) {
    this.minBirthday.setFullYear(this.minBirthday.getFullYear() - 150);
    this.form = this.fb.group({
      firstName: [data.name.firstName, [Validators.required, Validators.maxLength(30), TauresValidators.chars]],
      anrede: [null, [Validators.required]],
      lastName: [data.name.lastName, [Validators.required, Validators.maxLength(50), TauresValidators.chars]],
      birthdate: [null, [Validators.required]],
      addresses: this.fb.array([]),
      vp: null,
      betreuer: null
    });
    this.userService.getCurrentUser().pipe(takeUntil(this.destroy)).subscribe(currentUser => {
      this.form.get('vp').setValue(currentUser.id);
    });
    this.agentService.getCurrentSupervisor().pipe(takeUntil(this.destroy)).subscribe(supervisor => {
      this.form.get('betreuer').setValue(supervisor.id);
    });
    // same address when shared household
    if (this.data.addresses) {
      const formAddress = this.form.get('addresses') as UntypedFormArray;
      for (const address of this.data.addresses) {
        const status = address.status;
        if ((!status || status !== Status.DELETED) && address.postanschrift === true) {
          formAddress.push(new UntypedFormControl(address));
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.loading = true;
      this.customerService.createCustomer(this.form.value)
        .pipe(takeUntil(this.destroy))
        .subscribe(person => this.dialogRef.close(person), error => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            const customer = error.error as Person;
            if (!customer.kunde) {
              this.snackbar.open('Dieser Kunde existiert schon im System.', 'OK');
              this.dialogRef.close();
              return;
            }
            this.personService.getPerson(customer.kunde.betreuer)
              .pipe(takeUntil(this.destroy))
              .subscribe(
                consultant => {
                  if (consultant) {
                    this.snackbar.open(`Dieser Kunde existiert schon im System. Der Kunde wird
                      von ${consultant.vorname} ${consultant.nachname} betreut.
                      Um Zugriff auf diesen Kunden zu erhalten,
                      müssen die sich von ${consultant.vorname} ${consultant.nachname}
                      als Stellvertreter eintragen lassen.`, 'OK');
                    this.dialogRef.close();
                  }
                });
          }
        });
    }
  }
}

