import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import {Observable} from 'rxjs';
import {HistoryItemService, TaskHistoryItem, userDisplayName} from '../../../services/history-item.service';
import {map} from 'rxjs/operators';
import {Person, PersonService} from '../../../services/person.service';

@Component({
  selector: 'app-single-history-item',
  templateUrl: 'single-history-item.component.html',
  styleUrls: ['single-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleHistoryItemComponent {
  headerText: Observable<string>;
  itemMessage: Observable<string>;
  @HostBinding('class.mat-elevation-z2') true;

  constructor(private store: Store<fromRoot.State>,
              readonly personService: PersonService,
              private changeDetector: ChangeDetectorRef,
              private historyService: HistoryItemService) {
  }

  private currentHistoryItem: TaskHistoryItem;

  get historyItem(): TaskHistoryItem {
    return this.currentHistoryItem;
  }

  @Input()
  set historyItem(inp: TaskHistoryItem | null) {
    this.currentHistoryItem = inp;
    const personId = this.historyService.getPersonIdFromHistoryItem(inp);
    this.headerText = this.personService.getPerson(personId)
      .pipe(map(person => this.headerTextForUser(person)));
    this.itemMessage = this.historyService.historyItemMessage(this.historyItem);
  }

  get isComment(): boolean {
    return !!this.historyItem.coment;
  }

  get itemDate(): Date {
    return this.historyService.historyItemDate(this.historyItem);
  }

  private headerTextForUser(person: Person): string {
    return this.isComment ?
      'Kommentar von ' + userDisplayName(person) :
      'Aktion von ' + userDisplayName(person);
  }
}


