<div [formGroup]="variables" class="flex flex-column flex-grow-1 gap-3">
  <div *ngIf="addresses.invalid && addresses.errors && addresses.errors['required']" class="invalid">
    Es muss mindestens eine Adresse angegeben werden.
  </div>
  <div *ngIf="variables.get('customer').errors && variables.get('customer').errors['meldeanschriftRequired']"
       class="invalid">
    Es muss ein Hauptwohnsitz angegeben werden.
  </div>
  <div *ngIf="variables.get('customer').errors && variables.get('customer').errors['postanschriftRequired']"
       class="invalid">
    Es muss eine Postanschrift angegeben werden.
  </div>
  <div [formGroup]="customer" class="flex list">
    <div formArrayName="adressen" class="flex flex-wrap flex-grow-1 gap-3" *ngIf="getActiveContracts() | async as contracts; else addressesLoading">
      <app-single-addresse-full *ngFor="let address of addresses.controls | notDeleted; let i=index"
                                class="mb-3 address-width"
                                [formGroupName]="i"
                                [control]="address"
                                (deleteContact)="removeAddress(i)"
                                (setAsPrime)="setContactToPrime(i)"
                                (setAsMeldeAnschrift)="setMeldeAnschrift(i)"
                                [addressCount]="addresses.length"
                                [originalAddress]="getOriginalAddress(address.value)"
                                [contracts]="contracts">
      </app-single-addresse-full>
    </div>
    <ng-template #addressesLoading>
      <mat-progress-spinner mode="indeterminate" [diameter]="48" [strokeWidth]="2"></mat-progress-spinner>
    </ng-template>
  </div>
    <button type="button" style="color:white" mat-fab (click)="addAddress()" matTooltip="Adresse hinzufügen">
      <mat-icon>add</mat-icon>
    </button>
</div>

