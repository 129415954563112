import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {isNil} from 'lodash-es';
import {filter, map} from 'rxjs/operators';
import {cache} from "@taures/angular-commons";

const PERSON_URL = 't-it-s/rest/crm/v1.0/person/';
const PERSON_QUERY_URL = 't-it-s/rest/crm/v1.0/person/query';

export enum Status {
  DELETED = 'DELETED',
  MODIFIED = 'MODIFIED'
}

export interface Contract {
  id: number;
  tarif?: number;
  posteingang?: Date;
  postausgang?: Date;

  beginlaufzeit?: Date;
  laufzeitende?: Date;

  bewertungssumme?: number;
  laufzeitJahre?: number;
  laufzeitMonate?: number;
  bemerkung?: string;
  hauptvertrag?: number;
  identifikationsmerkmal?: number;
  abgeloesteVertraege?: { id: number; }[];
  vp?: number; // Provisionsempfanger
  consultant?: number; // Vertriebspartner
  antragart: string;
  antragsdatum?: Date;
  dokumente?: ContractDocument[];
  fremdvertrag?: boolean;
  tarifbeitrag?: string;
  zahlbeitrag?: string;
  buAntragVariante?: string;
  pkvAntragsqualitaet?: string;
  antragEmpfaenger?: string;
  zahlweise?: string;

  azaId?: number;
  newAzaId?: boolean;
  versichertePersonen?: any;

  buendelpolice?: number;
  uebergebenAnVU?: Date;
  vertrnummer?: string;

  gesellschaftsname?: string;
  sparte?: string;
  tarifBezeichnung?: string;
  antragsnummerVM?: string;
  korrespondenzmaklerschaft: boolean;
  gesellschaftKurzname?: string;
}

export interface Address {
  id?: string;
  adresseco?: string;
  strasse?: string;
  nummer?: number;
  plz?: string;
  stadt?: string;
  land?: string;
  meldeanschrift?: boolean;
  postanschrift?: boolean;
  aenderungsauftrag?: string;
  aenderungsauftragVertraege?: number[];
  aenderungsauftragForAllVertraege?: boolean;
  geschaeftsanschrift?: boolean;
  bemerkung?: string;
  gueltigAb?: Date;
  gueltigBis?: Date;
  status?: Status;
  zusatzdaten?: any;
}

export interface Surplus {
  id?: string;
  ueberschuss?: string;
  bruttoeinkommen?: string;
  nettoeinkommen?: string;
  vermoegen?: string;
  verbindlichkeiten?: string;
  datum: Date;
  status?: Status;
  bemerkung?: string;
}

export interface RelationshipType {
  uivalue: string;
  dbvalue: string;
  uivalueVon: string;
}

export interface Relationship {
  typ?: string;
  ziel?: string; // beziehung
  quelle?: string; // beziehungVon
  id?: string;
  status?: Status;
  erziehungsberechtigter?: string;
  wirtschaftlichAbhaengig?: boolean;
  kindergeldBis?: Date;
}

export interface Household {
  mitglieder: string[];
  oberhaupt: string;
}

export interface MaritalStatus {
  familienstand?: string;
  gueltigAb?: Date;
  id?: string;
  status?: Status;
}

export interface BankDetails {
  id?: string;
  iban?: string;
  inhaber?: number[];
  hauptverbindung?: boolean;
  bemerkung?: string;
  privat?: boolean;
  geschaeftlich?: boolean;
  gueltigAb?: Date;
  gueltigBis?: Date;
  bank?: string;
  bic?: string;
  konto?: string;
  blz?: string;
  status?: Status;
  aenderungsauftrag?: string;
  aenderungsauftragVertraege?: number[];
  aenderungsauftragForAllVertraege?: boolean;
}

export interface Contact {
  id?: number;
  eintrag?: string;
  vorrang: boolean;
  typ: number;
  status?: Status;
}

export interface Person {
  id: number;
  personManagementId?: string;
  vorname: string;
  nachname: string;
  titel?: string;
  anrede?: string;
  geburtsdatum?: Date;
  geburtsname?: string;
  geburtsort?: string;
  geburtsland?: string;
  nationalitaet?: string;
  bildungsabschluss?: string;
  sprache?: string;
  adressen?: Address[];
  ueberschuss?: Surplus[];
  bankverbindungen?: BankDetails[];
  beziehungen?: Relationship[];
  beziehungenVon?: Relationship[];
  haushalt?: Household;
  beschaeftigungsverhaeltnisse?: Employment[];
  familienstandHistorie?: MaritalStatus[];
  familienstand?: string;
  kommunikationsdaten?: Contact[];
  vertraege?: Contract[];
  identifikationsmerkmale?: IdentificationFeature[];
  dokumente?: CustomerDocument[];
  einwilligungKontaktTelefon?: string;
  anmerkungTelefon?: string;
  einwilligungKontaktMobil?: string;
  anmerkungMobil?: string;
  einwilligungKontaktEmail?: string;
  anmerkungEmail?: string;
  steuerId?: string;
  berufstatus?: number;
  kundenstatus?: string;
  kirchensteuerpflichtig: boolean;
  kunde: {
    bezahltPerRechnung: boolean;
    betreuer: number;
    vp: number;
    kundeSeit: Date;
    darlehensvermittlungDatum?: Date;
    servicevertragDatum?: Date;
    maklervertragDatum?: Date;
    maklervertragVersion?: {
      version: string;
      latestVersion: boolean;
    };
    makvollmBem?: string;
    maklervollmachtDatum?: Date;
    maklervollmachtVersion?: {
      version: string;
      latestVersion: boolean;
    };
    rvaDatum?: Date;
    rvaVersion?: {
      version: string;
      latestVersion: boolean;
    };
    steuerklasse?: string;
    steuerlichAnsaessigIn?: string;
    getrenntVeranlagt?: boolean;
    sozialversicherungsnummer?: string;
    gueterstand?: string;
    datenerfassungAbgeschlossen?: boolean;
    kategorievp: string;
    zusatzdaten?: { [key: string]: any };
    konzeptdoku?: boolean;
  };
}

export interface IdentificationFeature {
  id?: number;
  ausweisart?: number;
  nummer?: number;
  behoerde?: string;
  gueltigBis?: Date;
  ausstellungsdatum?: Date;
  dokument?: FileDocument;
  status?: Status;
}

export interface Employment {
  berufstatus?: string;
  arbeitgeber?: string;
  id?: string;
  primaer?: boolean;
  bezeichnung?: string;
  gueltigAb?: Date;
  gueltigBis?: Date;
  status?: Status;
  abteilung?: string;
  personalnummer?: string;
  zusatzdaten?: any;
}

export interface FileDocument {
  id?: string;
  fileId: string;
  filename: string;

  versendet?: Date;
  versendetAn?: string;
}

export interface ContractDocument extends FileDocument {
  typ: string; // i.e. kgg
  kommentar?: string;
}

export interface CustomerDocument extends FileDocument {
  typ: string; // i.e. kgg
  kommentar?: string;
  currentProcess?: boolean;
  insdate?: Date;
}


@Injectable()
export class PersonService {

  constructor(private readonly http: HttpClient) {
  }

  getPersons(filterConfig: { [key: string]: any | any[] }): Observable<Person[]> {
    let params = new HttpParams();
    for (const key of Object.keys(filterConfig)) {
      params = params.set(key, filterConfig[key]);
    }
    return this.http.get<Person[]>(PERSON_QUERY_URL, {params}).pipe(map(result =>
      result.sort((a, b) => {
        const aName = a.nachname + a.vorname;
        const bName = b.nachname + b.vorname;
        return aName.localeCompare(bName);
      })));
  }

  loadPerson(id: string | number): Observable<Person> {
    if (isNil(id)) {
      return EMPTY;
    }
    return this.http.get<Person>(PERSON_URL + id);
  }


  getPerson(id: string | number, skipCache: boolean = false): Observable<Person> {
    if (isNil(id)) {
      return EMPTY;
    }
    return this.loadPerson(id).pipe(cache(`person:${id}`, skipCache ? -1 : undefined));
  }

  getPersonByName(firstName: string, lastName: string, dateOfBirth: Date, exactMatch?: boolean): Observable<Person> {
    if (!(dateOfBirth instanceof Date)) {
      dateOfBirth = new Date(dateOfBirth);
    }
    if (exactMatch == null) {
      exactMatch = false;
    }
    const dateString = dateOfBirth.toISOString().substring(0, 10);
    return this.getPersons({lastName, firstName, dateOfBirth: dateString, exactMatch: exactMatch.toString()})
      .pipe(
        filter(persons => !!persons && persons.length > 0),
        map(persons => persons[0]),
        cache(`personByName:${firstName}-${lastName}-${dateString}`)
      );
  }
}


