import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Tariff} from '../../../../services/tariff.service';
import {Tariffs} from '../../../../tariffs';
import {Contract} from '../../../../services/person.service';

interface CheckableContract {
  contract: Contract;
  checked: boolean;
}

@Component({
  selector: 'app-multi-select-superseded-contract',
  templateUrl: 'multi-select-superseded.component.html',
  styleUrls: ['multi-select-superseded.component.scss']
})
export class MultiSelectSupersededComponent {
  localModel: CheckableContract[];
  @Output() resultList: EventEmitter<number[]> = new EventEmitter();

  constructor(private changeDetRef: ChangeDetectorRef) {
    this.localModel = [];
  }

  private priorContracts: Contract[];

  @Input()
  set allPriorContracts(value: Contract[]) {
    this.priorContracts = value;
    this.priorContracts.forEach(e => this.localModel.push({contract: e, checked: false} as CheckableContract));
    this.updateLocalModelFromPreCheckedOptions();
  }

  private tariffList: Tariff[];

  get tariffs(): Tariff[] {
    return this.tariffList;
  }

  @Input()
  set tariffs(value: Tariff[]) {
    this.tariffList = value;
    this.changeDetRef.markForCheck();
  }

  private initialValues: number[];

  @Input()
  set initialList(value: number[]) {
    this.initialValues = value;
    this.updateLocalModelFromPreCheckedOptions();
  }

  updateLocalModelFromPreCheckedOptions() {
    if (this.initialValues && this.initialValues.length && this.initialValues.length > 0 && this.localModel) {
      this.initialValues.forEach(initialContractID => {
        const contract = this.localModel.find(v => v.contract.id === initialContractID);
        if (contract) {
          contract.checked = true;
        }
      });
    }
    this.changeDetRef.markForCheck();
  }

  updateLocalModel(): void {
    this.resultList.emit(this.localModel
      .filter(e => e.checked === true)
      .map(e => e.contract.id)
    );
  }

  getInsurerName(tariffID: number): string {
    return Tariffs.insurerName(this.tariffs, tariffID);
  }

  getSparte(id: number): string {
    return Tariffs.sparteName(this.tariffs, id);
  }
}
