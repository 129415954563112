import {AsyncValidatorFn, UntypedFormGroup} from '@angular/forms';
import {from, isObservable, Observable, of} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ɵisPromise as isPromise} from '@angular/core';

import {Tariff} from '../../../services/tariff.service';
import {Contract, CustomerDocument, Person} from '../../../services/person.service';

export function hasRequiredDocumentsValidator(tariffs: Observable<Tariff[]>): AsyncValidatorFn {
  return (group: UntypedFormGroup) => {
    if (!tariffs) {
      return of(null);
    }
    return tariffs.pipe(
      take(1),
      map(t => {
        const customer = group.getRawValue() as Person;

        const result = {
          p11: p11Required(t, customer.vertraege, customer.dokumente),
          p12: p12Required(t, customer.vertraege, customer.dokumente),
          bda: bdaRequired(customer.dokumente),
          beo: beoBerGpRequired(customer.dokumente),
          ber: beoBerGpRequired(customer.dokumente),
          gp: beoBerGpRequired(customer.dokumente),
          sva: svaRequired(t, customer.vertraege, customer.dokumente),
          mvt: mvtRequired(customer, customer.dokumente),
          rva: rvaRequired(customer, customer.dokumente, t, customer.vertraege)
        };
        const requiredDocuments = Object.keys(result)
          .reduce((prev, key) => result[key] ? [...prev, key] : prev, []);
        if (requiredDocuments.length) {
          return {
            requiredDocuments,
          };
        }
        return null;
      }));
  };
}

export function hasRequiredContractDocumentsValidator(tariffs: Observable<Tariff[]>): AsyncValidatorFn {
  return (group: UntypedFormGroup) => {
    if (!tariffs) {
      return of(null);
    }
    return tariffs.pipe(
      take(1),
      map(t => {
        const contract = group.getRawValue() as Contract;

        const result = {
          // vvg: vvgRequired(t, contract)
        };
        const requiredDocuments = Object.keys(result)
          .reduce((prev, key) => result[key] ? [...prev, key] : prev, []);
        if (requiredDocuments.length) {
          return {
            requiredDocuments,
          };
        }
        return null;
      }));
  };
}

function p11Required(tariffs: Tariff[], contracts: Contract[], documents: CustomerDocument[]) {
  return contracts.some(c => hasSparte(tariffs, c, 412))
    && !documents.some(d => d && d.typ === 'p11');
}

function p12Required(tariffs: Tariff[], contracts: Contract[], documents: CustomerDocument[]) {
  return contracts.some(c => hasSparte(tariffs, c, 412))
    && !documents.some(d => d && d.typ === 'p12');
}

function bdaRequired(documents: CustomerDocument[]) {
  return !documents.some(d => d && d.typ === 'bda');
}

function beoBerGpRequired(documents: CustomerDocument[]) {
  return !documents.some(d => d && !d.id && (d.typ === 'ber' || d.typ === 'beo' || d.typ === 'gp'));
}

function svaRequired(tariffs: Tariff[], contracts: Contract[], documents: CustomerDocument[]) {
  return contracts.some(c => hasSparte(tariffs, c, 412))
    && !documents.some(d => d && d.typ === 'sva');
}

function mvtRequired(customer: Person, documents: CustomerDocument[]) {
  return (!customer.kunde.maklervertragVersion || !customer.kunde.maklervertragVersion.latestVersion)
    && !documents.some(d => d && d.typ === 'mvt' && !d.id);
}

function rvaRequired(customer: Person, documents: CustomerDocument[], tariffs: Tariff[], contracts: Contract[]) {
  return (!customer.kunde.rvaVersion || !customer.kunde.rvaVersion.latestVersion)
    && contracts.some(c => (c.id && c.id < 0)
      && (hasSparte(tariffs, c, 407) || hasSparte(tariffs, c, 410) || hasSparte(tariffs, c, 411) || hasSparte(tariffs, c, 419)))
    && !documents.some(d => d && d.typ === 'rva' && !d.id);
}

function vvgRequired(tariffs: Tariff[], c: Contract) {
  return (c.id && c.id < 0) && !hasSparte(tariffs, c, 406) && !hasSparte(tariffs, c, 412) && !c.dokumente.some(d => d && d.typ === 'vvg');
}

function hasSparte(tariffs: Tariff[], contract: Contract, sparteId: number) {
  const tariff = tariffOf(tariffs, contract);
  if (!tariff) {
    return false;
  }
  return tariff.vertragart.sparte.id === sparteId;
}

function tariffOf(tariffs: Tariff[], c: Contract): Tariff {
  return tariffs.find(t => t && c && t.id === c.tarif);
}

export function toObservable(r: any): Observable<any> {
  const obs = isPromise(r) ? from(r) : r;
  if (!(isObservable(obs))) {
    throw new Error(`Expected validator to return Promise or Observable.`);
  }
  return obs;
}
