<div class="flex align-items-center">
    <h1 mat-dialog-title class="flex-grow-1 mb-0">Änderungsauftrag</h1>
    <button mat-icon-button class="mr-2" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content class="pb-3">
    Für folgende Verträge wird die Änderung weitergeleitet:
    <div *ngIf="allAenderungsVertraege.aenderungsVertraege.length > 0" class="pt-3">
        <mat-checkbox class="background-color-checkbox checkbox-border flex flex-grow-1"
                [checked]="isAllVertraegeSelected"
                (change)="onAllVertraegeChange($event.checked)">
            Alle auswählen
        </mat-checkbox>
        <div class="max-h-11rem overflow-y-auto flex flex-column pb-2">
            <mat-checkbox class="checkbox-border" *ngFor="let vertrag of vertragSelections" [(ngModel)]="vertrag.checked"
                          (change)="onVertragChange()">
                {{ vertrag.name }}
            </mat-checkbox>
        </div>
    </div>
    <div *ngIf="allAenderungsVertraege.invalidAenderungsVertraege.length > 0" class="pt-2">
        <div class="flex flex-column gap-3 warning-hint-color p-3 b taures-border-radius">
            <div class="flex align-items-center">
                <mat-icon class="material-icons-outlined mr-2">warning_amber</mat-icon>
                <h3 class="mb-0 font-medium text-base">Warnung</h3>
            </div>
            <div>
                <p class="mb-0">{{ warningForInvalidVertraege }} Gleiches gilt für Fremdverträge, bei denen TauRes nicht
                    Korrespondenzmakler
                    ist.
                    <b>
                        Bitte informiere deinen Kunden darüber.
                    </b>
                </p>
            </div>
            <ul class="flex flex-column gap-2 max-h-7rem overflow-y-auto">
                <li class="opacity-50"
                    *ngFor="let vertrag of allAenderungsVertraege.invalidAenderungsVertraege">{{ vertrag.gesellschaftKurzname }}
                    - {{ vertrag.sparte }} - {{ vertrag.vertrnummer }}
                </li>
            </ul>
        </div>
        <div class="pt-4">
            Sollte einer der Fremdverträge abweichend bereits in TauRes-Betreuung (Bestand oder Korrespondenz) sein,
            melde dieses bitte über den <a [href]="kuemmerkastenUrl" target="_blank">Kümmerkasten</a>.
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="flex justify-content-end pt-2">
    <button type="button" mat-stroked-button (click)="closeDialog()">
        Abbrechen
    </button>
    <button type="button" mat-flat-button class="taures-primary-blue" (click)="updateSelectedVertraege()">
        Bestätigen
    </button>
</mat-dialog-actions>
