import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MaritalStatus, Status} from "../../../../services/person.service";

@Component({
  selector: 'app-single-marital-status',
  templateUrl: 'single-marital-status.component.html',
  styleUrls: ['single-marital-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleMaritalStatusComponent implements OnDestroy {
  @Output() removeCard: EventEmitter<any> = new EventEmitter();
  @Input() maritalStatusOptions: { [key: string]: string };

  private destroy: Subject<boolean> = new Subject();
  private group: UntypedFormGroup;

  get control() {
    return this.group;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.group = value;
    if (this.group.get('status') && this.isFromServer) {
      this.group.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.group.get('status').setValue(Status.MODIFIED, {emitEvent: false});
        });
    }
  }

  get isFromServer(): boolean {
    return !!this.control.controls.id.value;
  }

  get maritalStatusTypeKeys() {
    return Object.keys(this.maritalStatusOptions)
      .map(key => {
        return {key, value: this.maritalStatusOptions[key]};
      })
      .map(entry => entry.key);
  }

  public static buildGroup(fb: UntypedFormBuilder, maritalStatus: MaritalStatus,
                           validate: boolean = true) {
    const sinceValidators = [Validators.required];
    const nameValidators = [Validators.required];

    return fb.group({
      familienstand: [maritalStatus.familienstand, validate ? nameValidators : []],
      gueltigAb: [maritalStatus.gueltigAb, validate ? sinceValidators : []],
      status: maritalStatus.status,
      id: maritalStatus.id
    }, {updateOn: 'blur'});
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  deleteCard() {
    this.removeCard.emit(this.control);
  }

  disableRemove(): boolean {
    return this.group.get('familienstand').disabled;
  }
}
