import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';

import {generateId} from '../../../id-helper';
import {Tariff, TariffService} from '../../../services/tariff.service';
import {SingleIdentityComponent} from '../customer-identity/single-identity/single-identity.component';
import {SingleContractComponent} from './single-contract-full/single-contract-full.component';
import {TaskDefinition, Variables} from '../../../../shared/common/services/task.service';
import {User, UserService} from '@taures/angular-commons';
import {Contract, Person, Status} from '../../../services/person.service';
import {AppOptionsService, IdentityType} from '../../../services/app-options.service';
import {MatTabGroup} from "@angular/material/tabs";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-customer-contract-comparison',
  templateUrl: 'customer-contract.component.html',
  styleUrls: ['customer-contract.component.scss']
})
export class CustomerContractComponent implements OnInit {
  @Input()
  taskDefinition: TaskDefinition;
  contracts: UntypedFormArray;
  customer: UntypedFormGroup;
  currentUser: User;
  tariffs: Tariff[];
  identityTypes: Observable<IdentityType[]>;
  antragartTypes: Observable<{ [key: string]: string }>;
  antragEmpfaengerTypes: Observable<{ [key: string]: string }>;
  buAntragVariantenTypes: Observable<{ [key: string]: string }>;
  pkvAntragsqualitaeten: Observable<{ [key: string]: string }>;
  paymentOptions: Observable<{ uivalue: string, dbvalue: string }[]>;


  @ViewChild('tabGroup', {static: true}) tabGroup: MatTabGroup;
  public readonly MAX_NEW_CONTRACT_AMOUNT = 15;

  constructor(private fb: UntypedFormBuilder,
              readonly tariffService: TariffService,
              readonly options: AppOptionsService,
              private userService: UserService,
              private changeDetector: ChangeDetectorRef
  ) {
  }

  private variablesGroup: UntypedFormGroup;

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.customer = (this.variablesGroup.controls.customer as UntypedFormGroup);
    this.contracts = (this.customer.controls.vertraege as UntypedFormArray);
  }

  get newContracts() {
    return this.contracts.controls.filter(control => control.get('id').value < 0);
  }

  public static buildGroup(fb: UntypedFormBuilder, taskDefinition: TaskDefinition, variables: Variables, tariffs: Tariff[]) {
    const identities = fb.array([]);
    const customer = variables.customer as Person;
    const contractsFormArray = fb.array([]);
    const softfairCustomer = variables.softfairCustomer;

    if (customer && customer.vertraege) {
      customer.vertraege
        .forEach((contract) => {
          const softfairContracts = softfairCustomer && softfairCustomer.vertraege;
          const softfairContract = softfairContracts ? softfairContracts.find(e => e.id.toString() === contract.id.toString()) : null;
          contractsFormArray.push(SingleContractComponent.buildGroup(fb, contract, customer, taskDefinition,
            true, [], false, false, softfairContract, tariffs.find(t => t.id === contract.tarif)));
        });
    }

    if (customer && customer.identifikationsmerkmale) {
      customer.identifikationsmerkmale.forEach(e => identities.push(SingleIdentityComponent.buildGroup(fb, e, e.status !== Status.DELETED)));
    }

    return fb.group({
      customer: fb.group({
        vertraege: contractsFormArray,
        identifikationsmerkmale: identities,
        geburtsdatum: customer.geburtsdatum
      })
    });
  }

  ngOnInit() {
    this.userService.getCurrentUser().pipe(take(1)).subscribe(user => {
      this.currentUser = user
    });
    this.tariffs = this.tariffService.tariffs;
    this.identityTypes = of(this.options.identificationTypes);
    this.antragartTypes = of(this.options.antragartTypes);
    this.antragEmpfaengerTypes = of(this.options.antragEmpfaengerTypes);
    this.buAntragVariantenTypes = of(this.options.buAntragVarianten);
    this.pkvAntragsqualitaeten = of(this.options.pkvAntragsqualitaeten);
    this.paymentOptions = of(this.options.paymentOptions);
  }

  addContract() {
    if (this.newContracts.length < this.MAX_NEW_CONTRACT_AMOUNT) {
      this.contracts.push(
        SingleContractComponent.buildGroup(this.fb, {
          id: generateId(),
          consultant: this.currentUser.personId,
          vp: this.currentUser.personId,
          fremdvertrag: false,
          korrespondenzmaklerschaft: false,
          antragart: null,
          antragsdatum: new Date()
        }, this.customer.value, this.taskDefinition, true, [], true)
      );
      this.changeDetector.markForCheck();
      this.openTab(this.getFilteredContracts().length);
    }
  }

  removeContract(id: number) {
    for (let i = 0; i < this.contracts.length; i++) {
      if (this.contracts.at(i).get('id').value === id) {
        this.contracts.removeAt(i);
        if (this.tabGroup.selectedIndex === this.newContracts.length + 1) {
          this.tabGroup.selectedIndex--;
        }
        break;
      }
    }
  }

  openTab(index: number) {
    this.tabGroup.selectedIndex = index;
  }

  contractLabel(index: number) {
    return `Vertrag ${index + 1}`;
  }

  isBuendelpolice(contract: AbstractControl) {
    return this.contracts.controls.some(control => control.get('buendelpolice').value === contract.get('id').value);
  }

  isHauptvertrag(contract: AbstractControl) {
    return this.contracts.controls.some(control => control.get('hauptvertrag').value === contract.get('id').value);
  }

  getBuendelpolicen(): Contract[] {
    const buendelpoliceIds: number[] = [];
    const buendelpolicen: Contract[] = [];
    this.contracts.controls.forEach(contract => {
      const buendelpoliceIdControl = contract.get('buendelpolice');
      if (buendelpoliceIdControl && buendelpoliceIdControl.value && !buendelpoliceIds.includes(buendelpoliceIdControl.value)) {
        buendelpoliceIds.push(buendelpoliceIdControl.value);
      }
    });
    buendelpoliceIds.forEach(id => {
      const buendelpolice = this.contracts.controls.find(contract => contract.get('id').value === id);
      if (buendelpolice) {
        buendelpolicen.push(buendelpolice.value);
      }
    });
    return buendelpolicen;
  }

  createBuendelpolice(contract: Contract) {
    const sourceContract = this.contracts.controls.find(control => control.get('id').value === contract.id);
    contract.id = generateId();
    sourceContract.get('buendelpolice').setValue(contract.id);
    this.contracts.push(SingleContractComponent.buildGroup(this.fb, contract, this.customer.value, this.taskDefinition, false, [], false));
  }

  getFilteredContracts() {
    return this.newContracts.filter(contract => !this.isBuendelpolice(contract));
  }
}
