import {Component, Inject, OnDestroy} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from "@angular/material/dialog";
import {AenderungsVertraege} from "../../services/customer.service";
import {Subject} from "rxjs";
import {environment} from "../../../environments/environment";

interface Vertrag {
  vertragId: number;
  name: string
  checked: boolean;
}

export interface AenderungsauftragDialogData {
  vertraege: AenderungsVertraege
  selectedVertraege?: number[],
  aenderungsauftragType: AenderungsauftragType
}

export interface AenderungsauftragDialogResult {
  selectedVertraege: number[],
  alleVertraegeSelected: boolean
}

export type AenderungsauftragType = 'Adressdatenänderung' | 'Bankdatenänderung';

@Component({
  selector: 'app-aenderungsauftrag-dialog',
  templateUrl: './aenderungsauftrag-dialog.component.html',
  styleUrl: './aenderungsauftrag-dialog.component.scss'
})

export class AenderungsauftragDialogComponent implements OnDestroy {

  allAenderungsVertraege: AenderungsVertraege;
  isAllVertraegeSelected: boolean = false;
  vertragSelections: Vertrag[] = [];
  warningForInvalidVertraege: string;

  private destroy = new Subject<void>();

  protected readonly kuemmerkastenUrl = environment.kuemmerkastenUrl;

  constructor(private dialogRef: MatDialogRef<AenderungsauftragDialogComponent, AenderungsauftragDialogResult>,
              @Inject(MAT_DIALOG_DATA) public data: AenderungsauftragDialogData) {
    if (this.data.aenderungsauftragType === 'Adressdatenänderung') {
      this.warningForInvalidVertraege =
        'Die Weiterleitung von Adressänderungen ist bei GKV-Verträgen, Banken, Depotbanken (mit Ausnahme der FFB), Geldanlagen, Darlehen und Verträgen der SVBO nicht über den Vertragsservice möglich, da die Änderung durch den Kunden erfolgen muss.'
    } else {
      this.warningForInvalidVertraege =
        'Die Weiterleitung von Bankdatenänderungen ist bei GKV-Verträgen, Banken, Depotbanken, Geldanlagen, Darlehen und Verträgen der SVBO nicht über den Vertragsservice möglich, da die Änderung durch den Kunden erfolgen muss.'
    }
    this.allAenderungsVertraege = this.data.vertraege;
    this.vertragSelections = this.allAenderungsVertraege.aenderungsVertraege
      .map(vertrag => ({
        vertragId: vertrag.id,
        name: `${vertrag.gesellschaftKurzname} - ${vertrag.sparte} - ${vertrag.vertrnummer}`,
        checked: this.data.selectedVertraege ? this.data.selectedVertraege.includes(vertrag.id) : false
      }));
    this.onVertragChange();
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete()
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onVertragChange() {
    this.isAllVertraegeSelected = this.vertragSelections !== null && this.vertragSelections.every(vertrag => vertrag.checked);
  }

  onAllVertraegeChange(checked: boolean) {
    this.isAllVertraegeSelected = checked;
    this.vertragSelections.forEach(vertrag => vertrag.checked = checked);
  }

  updateSelectedVertraege() {
    const selectedAenderungsauftragVertraege = this.vertragSelections.filter(vertrag => vertrag.checked).map(vertrag => vertrag.vertragId);
    this.dialogRef.close({selectedVertraege: selectedAenderungsauftragVertraege, alleVertraegeSelected: this.isAllVertraegeSelected});
  }
}
