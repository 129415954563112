import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Tariff, TariffService} from '../../../../services/tariff.service';
import {PlzLookupService} from '../../../../services/plz-lookup.service';
import {COUNTRY_CODES, GERMANY_COUNTRY_CODE} from '../../../../services/country-codes';
import {AenderungsVertraege} from "../../../../services/customer.service";
import {MatDialog} from "@angular/material/dialog";
import {
  AenderungsauftragDialogComponent,
  AenderungsauftragDialogData,
  AenderungsauftragDialogResult
} from "../../../aenderungsauftrag-dialog/aenderungsauftrag-dialog.component";
import {Address, Status} from "../../../../services/person.service";


@Component({
  selector: 'app-single-addresse-full',
  templateUrl: 'single-addresse-full.component.html',
  styleUrls: ['single-addresse-full.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleAddresseFullComponent implements OnDestroy, OnInit {
  @Output() deleteContact: EventEmitter<any> = new EventEmitter();
  @Output() setAsPrime: EventEmitter<any> = new EventEmitter();
  @Output() setAsMeldeAnschrift: EventEmitter<any> = new EventEmitter();
  @Input() addressCount: number;
  @Input() contracts: AenderungsVertraege;
  @Input() originalAddress: Address;
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  @ViewChild('countryAutocomplete', {static: true}) countryAutocomplete: MatAutocomplete;
  public placeNames: Observable<string[]>;
  tariffs: Tariff[];

  private destroy: Subject<boolean> = new Subject();

  constructor(private changeDetector: ChangeDetectorRef,
              private plzLookup: PlzLookupService,
              private tariffService: TariffService, private aenderungsauftragDialog: MatDialog) {
  }

  private group: UntypedFormGroup;

  get control() {
    return this.group;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.group = value;
    if (this.group.get('status') && this.isFromServer) {
      this.group.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe(() => this.group.get('status').setValue(Status.MODIFIED, {emitEvent: false}));
    }
    this.group.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.changeDetector.detectChanges());
    this.group.get('land').valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.changeLand());
    this.group.get('plz').valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => this.getPlaceNames());
  }

  get isFromServer(): boolean {
    return !!this.control.controls.id.value;
  }

  get isPrimary(): boolean {
    return !!this.control.controls.postanschrift.value;
  }

  public static initGroup(fb: UntypedFormBuilder, address: Address, addressLength: number, originalAddress: Address, validation: boolean = true) {
    const adressecoValidators = [];
    const strasseValidators = [];
    const nummerValidators = [];
    const plzValidators = [];
    const stadtValidators = [];
    const landValidators = [];
    const gueltigAbValidators = [];
    const gueltigBisValidators = [];
    const aenderungsauftragValidators = [];
    if (!address.id) {
      strasseValidators.push(Validators.required);
      nummerValidators.push(Validators.required);
      plzValidators.push(Validators.required);
      stadtValidators.push(Validators.required);
      landValidators.push(Validators.required);
      gueltigAbValidators.push(Validators.required);
      gueltigBisValidators.push(Validators.required);
    }
    // either new address or new postanschrift
    if ((!address.id && addressLength > 1) || (address.id && originalAddress && !originalAddress.postanschrift && address.postanschrift)) {
      aenderungsauftragValidators.push(Validators.required);
    }

    address.land = !address.id && !address.land ? GERMANY_COUNTRY_CODE : address.land;

    return fb.group({
      id: [address.id],
      status: [address.status],
      adresseco: [{value: address.adresseco, disabled: !!address.id}, validation ? adressecoValidators : []],
      strasse: [{value: address.strasse, disabled: !!address.id}, validation ? strasseValidators : []],
      nummer: [{value: address.nummer, disabled: !!address.id}, validation ? nummerValidators : []],
      plz: [{value: address.plz, disabled: !!address.id}, validation ? plzValidators : []],
      stadt: [{value: address.stadt, disabled: !!address.id}, validation ? stadtValidators : []],
      land: [{value: address.land, disabled: !!address.id}, validation ? landValidators : []],
      meldeanschrift: [{value: address.meldeanschrift, disabled: false}],
      geschaeftsanschrift: [{value: address.geschaeftsanschrift, disabled: !!address.id}],
      postanschrift: [{value: address.postanschrift, disabled: false}],
      aenderungsauftragVertraege: [address.aenderungsauftragVertraege],
      aenderungsauftragForAllVertraege: [address.aenderungsauftragForAllVertraege],
      bemerkung: [{value: address.bemerkung, disabled: false}],
      gueltigAb: [{value: address.gueltigAb, disabled: !!address.id}, validation ? gueltigAbValidators : []],
      gueltigBis: address.gueltigBis,
      zusatzdaten: address.zusatzdaten
    });
  }

  ngOnInit(): void {
    this.tariffs = this.tariffService.tariffs;
    if (this.control.get('aenderungsauftragForAllVertraege').value === true) {
      this.control.get('aenderungsauftragVertraege').setValue(this.contracts.aenderungsVertraege.map(vertrag => vertrag.id));
    }
  }

  getPlaceNames() {
    const plz = this.group.get('plz');
    const country = this.group.get('land');
    if (plz.valid) {
      this.placeNames = this.plzLookup.getPlaceNames(plz.value, country.value);
      this.placeNames.pipe(takeUntil(this.destroy)).subscribe(placeNames => {
        if (placeNames.length === 1) {
          this.group.get('stadt').setValue(placeNames[0]);
        }
      });
    } else {
      this.placeNames = new Observable<string[]>(observer => {
        observer.next([]);
      });
    }
  }

  changeLand() {
    if (this.control.get('land').value === GERMANY_COUNTRY_CODE) {
      this.control.get('plz').setValidators(
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
          Validators.pattern(/^[0-9]*$/)
        ]
      );
    } else {
      this.control.get('plz').setValidators([Validators.required]);
    }
    this.control.get('plz').updateValueAndValidity();
    this.getPlaceNames();
  }

  ngOnDestroy() {
    this.destroy.next(true);
    this.destroy.complete();
  }

  deleteCard() {
    this.deleteContact.emit(this.control);
  }

  setCardAsPrimary() {
    this.control.get('gueltigAb').setValue(new Date());
    this.setAsPrime.emit(this.control);
  }

  isPostanschrift(): boolean {
    return this.control.get('postanschrift').value;
  }

  meldeAnschriftChanged() {
    this.control.get('gueltigAb').setValue(new Date());
    this.setAsMeldeAnschrift.emit(this.control);
  }

  showAenderungsauftrag() {
    return this.contracts && this.contracts.aenderungsVertraege.length > 0 && ((this.control.get('id').value === null && this.addressCount > 1) ||
      (this.originalAddress && !this.originalAddress.postanschrift && this.control.get('postanschrift').value));
  }

  setAenderungsauftragVertraege(aenderungsauftragDialogResult: AenderungsauftragDialogResult) {
    this.control.get('aenderungsauftragVertraege').setValue(aenderungsauftragDialogResult.selectedVertraege);
    this.control.get('aenderungsauftragForAllVertraege').setValue(aenderungsauftragDialogResult.alleVertraegeSelected);
  }

  showAenderungsauftagDialog() {
    const selectedVertrage = this.control.get('aenderungsauftragVertraege').value;
    this.aenderungsauftragDialog.open<AenderungsauftragDialogComponent, AenderungsauftragDialogData, AenderungsauftragDialogResult>
    (AenderungsauftragDialogComponent, {
      width: '672px',
      panelClass: 'aenderungsauftrag-dialog-content-height',
      data: {
        vertraege: this.contracts,
        selectedVertraege: selectedVertrage,
        aenderungsauftragType: 'Adressdatenänderung',

      }
    }).afterClosed().subscribe((result: AenderungsauftragDialogResult) => {
      if (result !== null && result !== undefined) {
        this.setAenderungsauftragVertraege(result);
      }
    });
  }

  hasAenderungsauftrag() {
    const aenderungsauftragVertraege = this.control.get('aenderungsauftragVertraege').value;
    return !(aenderungsauftragVertraege === null || aenderungsauftragVertraege === undefined) && aenderungsauftragVertraege.length > 0;
  }

  showAenderungsauftragWarningMessage() {
    return "Zur automatischen Weiterleitung der Adressänderung erstelle bitte einen Änderungsauftrag."
  }


  showAenderungsauftragInfoMessage(): boolean {
    const aenderungsauftragVertraegeList = this.control.get('aenderungsauftragVertraege').value;
    return aenderungsauftragVertraegeList.length === this.contracts.aenderungsVertraege.length;
  }
}
