import {Component, Input} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService} from '../../../services/app-options.service';
import {CustomValidators} from '@taures/angular-commons';
import {Person} from '../../../services/person.service';
import {COUNTRY_CODES} from '../../../services/country-codes';

@Component({
  selector: 'app-tax-infos',
  templateUrl: './tax-infos.component.html',
  styleUrls: ['./tax-infos.component.scss']
})
export class TaxInfosComponent {

  @Input()
  customer: Person;
  @Input()
  variables: UntypedFormGroup;

  readonly softfairRowWidths = [1, 2, 5, 3];
  readonly regularRowWidths = [2, 0, 5, 4];
  sortedCountries: { code: string, name: string }[] = COUNTRY_CODES;
  gueterstaende: {[key: string]: string};
  steuerklassen: {[key: string]: string};

  constructor(readonly options: AppOptionsService) {
    this.gueterstaende = this.options.gueterstand;
    this.steuerklassen = this.options.steuerklasse;
  }

  get gueterstandeKeys() {
    return Object.keys(this.gueterstaende)
      .map(key => {
        return {key, value: this.gueterstaende[key]};
      })
      .map(entry => entry.key);
  }

  get steuerklassenKeys() {
    return Object.keys(this.steuerklassen)
      .map(key => {
        return {key, value: this.steuerklassen[key]};
      })
      .map(entry => entry.key);
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const customer = variables.customer || {};
    const kunde = customer.kunde || {};
    const softfairCustomer = variables.softfairCustomer || {};
    return fb.group({
      customer: fb.group({
        steuerId: [customer.steuerId, [CustomValidators.steuerId]],
        kirchensteuerpflichtig: [customer.kirchensteuerpflichtig],
        kunde: fb.group({
          steuerklasse: [kunde.steuerklasse],
          steuerlichAnsaessigIn: [kunde.steuerlichAnsaessigIn],
          getrenntVeranlagt: [kunde.getrenntVeranlagt],
          sozialversicherungsnummer: [kunde.sozialversicherungsnummer, [Validators.minLength(12)]],
          gueterstand: [kunde.gueterstand]
        })
      }),
      softfairCustomer: fb.group({
        geburtsname: softfairCustomer.geburtsname,
        geburtsort: softfairCustomer.geburtsort,
        geburtsdatum: softfairCustomer.geburtsdatum,
        titel: softfairCustomer.titel,
        vorname: softfairCustomer.vorname,
        nachname: softfairCustomer.nachname,
      })
    });
  }

  get rowWidths(): Array<number> {
    return (this.hasSoftfairCustomer()) ? this.softfairRowWidths : this.regularRowWidths;
  }

  public hasSoftfairCustomer(): boolean {
    if (!this.variables) {
      return false;
    }

    return (
      !!this.variables.get('softfairCustomer.titel').value ||
      !!this.variables.get('softfairCustomer.vorname').value ||
      !!this.variables.get('softfairCustomer.nachname').value
    );
  }

  steuerlichAnsaessigInOfCustomer(customer: Person): string {
    return customer && customer.kunde && customer.kunde.steuerlichAnsaessigIn ? COUNTRY_CODES
      .find(c => c.code.toLowerCase() === customer.kunde.steuerlichAnsaessigIn.toLowerCase()).name : '';
  }
}
