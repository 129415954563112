import {Component, Input} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SingleEmploymentComponent} from './single-employment/single-employment.component';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService, EmploymentType} from '../../../services/app-options.service';
import {Person, Status} from '../../../services/person.service';

@Component({
  selector: 'app-customer-employments-comparison',
  templateUrl: 'customer-employments.component.html',
  styleUrls: ['customer-employments.component.scss']
})
export class CustomerEmploymentsComponent {
  customer: UntypedFormGroup;
  beschaeftigungsverhaeltnisse: UntypedFormArray;
  employmentTypes: EmploymentType[];
  private variablesGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, readonly options: AppOptionsService) {
    this.employmentTypes = this.options.employmentTypes;
  }

  get variables() {
    return this.variablesGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variablesGroup = value;
    this.customer = (this.variables.controls.customer as UntypedFormGroup);
    this.beschaeftigungsverhaeltnisse = (this.customer.controls.beschaeftigungsverhaeltnisse as UntypedFormArray);
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const customer = variables.customer as Person;
    const employments = fb.array([]);

    if (customer && customer.beschaeftigungsverhaeltnisse) {
      customer.beschaeftigungsverhaeltnisse.forEach(employment => {
        if (employment.primaer) {
          employments.insert(0,
            SingleEmploymentComponent.buildGroup(fb, employment, employment.status !== Status.DELETED));
        } else {
          employments.push(
            SingleEmploymentComponent.buildGroup(fb, employment, employment.status !== Status.DELETED));
        }
      });
    }

    return fb.group({
      customer: fb.group({
        beschaeftigungsverhaeltnisse: employments
      })
    });
  }

  addCard() {
    this.beschaeftigungsverhaeltnisse.push(SingleEmploymentComponent.buildGroup(this.fb, {
      primaer: !this.hasPrimary(),
      gueltigAb: new Date()
    }));
  }

  hasPrimary(): boolean {
    let j = 0;
    while (j < this.beschaeftigungsverhaeltnisse.length) {
      const control = this.beschaeftigungsverhaeltnisse.at(j);
      const status = control.get('status');
      if ((!status || status.value !== Status.DELETED) && control.get('primaer').value === true) {
        return true;
      }
      j++;
    }
    return false;
  }

  setToPrime(i: number) {
    const target = this.beschaeftigungsverhaeltnisse.at(i);
    let j = 0;
    while (j < this.beschaeftigungsverhaeltnisse.length) {
      this.beschaeftigungsverhaeltnisse.at(j).patchValue({primaer: false});
      j++;
    }
    target.patchValue({primaer: true});
  }

  removeCard(i: number) {
    const target = this.beschaeftigungsverhaeltnisse.at(i);
    const wasPrimary = target.get('primaer').value === true;
    if (target.get('id').value) {
      target.patchValue({status: Status.DELETED}, {emitEvent: false});
    } else {
      this.beschaeftigungsverhaeltnisse.removeAt(i);
    }

    if (wasPrimary && this.beschaeftigungsverhaeltnisse.length > 0) {
      this.beschaeftigungsverhaeltnisse.at(0).patchValue({primaer: true});
    }
  }
}
