import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ReworkFormComponent} from './rework-form/rework-form.component';
import {ReworksService} from '../../../services/reworks.service';
import {Variables} from '../../../../shared/common/services/task.service';
import {AppOptionsService} from '../../../services/app-options.service';
import {map, take} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {Contract} from '../../../services/person.service';
import {Tariff, TariffService} from '../../../services/tariff.service';
import {CustomerService} from '../../../services/customer.service';
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../reducers";

@Component({
  selector: 'app-task-reworks-comparison',
  templateUrl: 'task-reworks.component.html',
  styleUrls: ['task-reworks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskReworksComponent implements OnInit {

  reworks: UntypedFormArray;
  crmReworks: Observable<UntypedFormGroup[]>;
  contracts: Observable<Contract[]>;
  tariffs: Observable<Tariff[]>;
  private variableGroup: UntypedFormGroup;

  constructor(private store: Store<fromRoot.State>, private fb: UntypedFormBuilder, private reworksService: ReworksService,
              readonly options: AppOptionsService, readonly tariffService: TariffService, private customerService: CustomerService) {
    this.tariffs = of(this.tariffService.tariffs);
  }

  get variables() {
    return this.variableGroup;
  }

  @Input()
  set variables(value: UntypedFormGroup) {
    this.variableGroup = value;
    this.reworks = (this.variables.controls.reworks as UntypedFormArray);
    const customerId = this.variables.get('customer.id').value;
    if (customerId) {
      this.crmReworks = this.reworksService.getReworks(customerId).pipe(
        take(1),
        map((crmReworks) => {
          const crmReworkIds = crmReworks.map(r => r.id);
          this.reworks.controls = this.reworks.controls.filter(vr => !vr.get('id').value || crmReworkIds.includes(vr.get('id').value));

          let mergedReworks = crmReworks
            .map(rework => ReworkFormComponent.initGroup(this.fb, rework, false, false))
            .filter(r => !this.reworks.controls.find(c => c.get('id').value === r.get('id').value));
          mergedReworks = mergedReworks.concat(this.reworks.controls.filter(c => c.get('id').value) as UntypedFormGroup[])
            .sort((a, b) => ReworksService.compareDate(a.get('createDate').value, b.get('createDate').value));
          mergedReworks.forEach(rw => {
            if (rw.get('objekttyp').value === 'V') {
              rw.get('objekt').setValidators([Validators.required]);
              rw.get('objekt').updateValueAndValidity();
            }
          });
          return mergedReworks;
        }));
    }
  }

  public static buildGroup(fb: UntypedFormBuilder, variables: Variables) {
    const reworksControl = fb.array([]);
    if (variables && variables.reworks) {
      variables.reworks.forEach(rework => reworksControl.push(ReworkFormComponent.initGroup(fb, rework)));
    }
    const customer = variables.customer || {};
    return fb.group({
      customer: fb.group({id: [customer.id, [Validators.required]]}),
      reworks: reworksControl
    });
  }

  ngOnInit() {
    this.contracts = combineLatest([
      this.customerService.loadContracts(this.variables.get('customer.id').value),
      this.store.pipe(select(fromRoot.getCurrentContract)),
      this.tariffs
    ]).pipe(
      map(([contracts, currentContract, tariffs]) => {
        contracts = contracts.sort((a, b) => {
          const tariffA = tariffs.find(t => t.id === a.tarif)
          const tariffB = tariffs.find(t => t.id === b.tarif)
          return tariffA && tariffB ? tariffA.gesellschaft.kurzname.localeCompare(tariffB.gesellschaft.kurzname) : 0
        })
        return (currentContract ?? []).concat(contracts)
      })
    )
  }

  addRework() {
    const control = ReworkFormComponent.initGroup(this.fb, {objekttyp: 'K', status: '170', infoAnBetreuer: true});
    this.reworks.push(control);
  }

  removeRework(control: AbstractControl) {
    this.reworks.removeAt(this.reworks.controls.indexOf(control));
  }

  addReworkToVariables(reworkGroup: UntypedFormGroup) {
    this.reworks.push(reworkGroup);
  }

  getNewReworks() {
    return this.reworks.controls.filter(rework => !rework.get('id').value);
  }

}
