import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {PersonService} from './person.service';
import {Observable, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UniquePersonValidatorService {

  constructor(readonly personService: PersonService) {
  }

  validator(personId: number): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      if (control instanceof UntypedFormGroup) {
        const vornameCtlr = control.get('vorname');
        const nachnameCtrl = control.get('nachname');
        const geburtsdatumCtrl = control.get('geburtsdatum');
        if (!vornameCtlr || !nachnameCtrl || !geburtsdatumCtrl) {
          return of(null);
        }
        return this.personService
          .getPersonByName(vornameCtlr.value, nachnameCtrl.value, geburtsdatumCtrl.value, true)
          .pipe(
            filter(person => person.id !== personId),
            map(person => (person ? {uniquePerson: person.id} : null)),
            catchError(() => of(null))
          );
      }
      return of(null);
    };
  }
}
