import {isArray, isEqual, isNil, isObject} from 'lodash-es';
import {Task, TaskDefinition} from '../shared/common/services/task.service';
import {Person} from "./services/person.service";

function isEqualIngoringOrder(a: any[], b: any[]) {
  if (!a && !b) {
    return true;
  }
  if (!a || !b || a.length !== b.length) {
    return false;
  }
  return a.reduce((prev, cur) => {
    const find = b.find(value => value.id === cur.id);
    const equal = isEqual(find, cur);
    return prev && find && equal;
  }, true);
}

export enum StepSlug {
  CUSTOMER_BASIC = 'customer-basic',
  CUSTOMER_CONTACT = 'customer-contact',
  CUSTOMER_ADDRESSES = 'customer-addresses',
  CUSTOMER_BANK = 'customer-bank',
  CUSTOMER_CONTRACT = 'customer-contract',
  CUSTOMER_CONTRACT_SINGLE = 'customer-contract-single',
  CUSTOMER_IDENTITY = 'customer-identity',
  CUSTOMER_SURPLUS = 'customer-surplus',
  DOCUMENTS_OVERVIEW = 'documents',
  CUSTOMER_BROKER_CONTRACT = 'broker-contract',
  CUSTOMER_RELATIONSHIPS = 'relationships',
  CUSTOMER_TAX_INFOS = 'tax-infos',
  CUSTOMER_EMPLOYMENTS = 'employments',
  CUSTOMER_MARITAL_STATUS = 'marital_status',
  TASK_REWORK_OVERVIEW = 'task_rework',
  UNKNOWN = 'unknown'
}

export interface Step {
  title: string;
  slug: string;
  canSkip: (task: Task, customer: Person) => boolean;
}

export const UNKNOWN: Step = {
  title: 'unknown',
  slug: StepSlug.UNKNOWN,
  canSkip: () => {
    return false;
  }
};

export const CUSTOMER_BASIC: Step = {
  title: 'Persönliche Daten',
  slug: StepSlug.CUSTOMER_BASIC,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    // only check the first layer - don't check arrays
    return Object.keys(task.variables.customer as Person)
      .map(prop => {
        const value = task.variables.customer[prop];
        if (isArray(value)) {
          return true;
        }
        if (isObject(value)) {
          return true;
        }
        if (isNil(value) && isNil(customer[prop])) {
          return true;
        }

        return isEqual(value, customer[prop]);
      })
      .reduce((prev, cur) => prev && cur, true);
  }
};

export const CUSTOMER_CONTACT: Step = {
  title: 'Kontaktdaten',
  slug: StepSlug.CUSTOMER_CONTACT,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const contacts = (task.variables.customer as Person).kommunikationsdaten;
    return isEqualIngoringOrder(contacts, customer.kommunikationsdaten);
  }
};

export const CUSTOMER_REWORK: Step = {
  title: 'Nachbearbeitungen',
  slug: StepSlug.TASK_REWORK_OVERVIEW,
  canSkip: (task) => {
    return false;
  }
};


export const CUSTOMER_RELATIONSHIP: Step = {
  title: 'Beziehungen',
  slug: StepSlug.CUSTOMER_RELATIONSHIPS,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const relationships = (task.variables.customer as Person).beziehungen;
    return isEqualIngoringOrder(relationships, customer.beziehungen);
  }
};

export const CUSTOMER_TAX_INFOS: Step = {
  title: 'Steuerliche Merkmale',
  slug: StepSlug.CUSTOMER_TAX_INFOS,
  canSkip: () => {
    return false;
  }
};

export const CUSTOMER_MARITAL_STATUS: Step = {
  title: 'Familienstand',
  slug: StepSlug.CUSTOMER_MARITAL_STATUS,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const maritalStati = (task.variables.customer as Person).familienstandHistorie;
    return isEqualIngoringOrder(maritalStati, customer.familienstandHistorie);
  }
};

export const CUSTOMER_EMPLOYMENT: Step = {
  title: 'Beschäftigungsverhältnisse',
  slug: StepSlug.CUSTOMER_EMPLOYMENTS,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const employments = (task.variables.customer as Person).beschaeftigungsverhaeltnisse;
    return isEqualIngoringOrder(employments, customer.beschaeftigungsverhaeltnisse);
  }
};

export const BROKER_CONTRACT: Step = {
  title: 'Maklervertrag',
  slug: StepSlug.CUSTOMER_BROKER_CONTRACT,
  canSkip: (task) => {
    if (task.formKey === TaskDefinition.FollowUpAgent) {
      return false;
    }
    if (!!task.variables.isBackOfficeCheckRequested) {
      return false;
    }

    const customer = (task.variables.customer as Person);
    if (customer.dokumente && customer.dokumente.find(doc => doc.typ === 'mvt')) {
      return false;
    }
    return true;
  }
};

export const CUSTOMER_ADDRESSES: Step = {
  title: 'Adressdaten',
  slug: StepSlug.CUSTOMER_ADDRESSES,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const addressData = (task.variables.customer as Person).adressen;
    return isEqualIngoringOrder(addressData, customer.adressen);
  }
};

export const CUSTOMER_SURPLUS: Step = {
  title: 'Überschuss',
  slug: StepSlug.CUSTOMER_SURPLUS,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const surplusData = (task.variables.customer as Person).ueberschuss;
    // since this is a new feature some customers don't have this field
    if (!customer.ueberschuss && (!surplusData || surplusData.length === 0)) {
      return true;
    }
    return isEqualIngoringOrder(surplusData, customer.ueberschuss);
  }
};

export const CUSTOMER_BANK: Step = {
  title: 'Bankverbindungen',
  slug: StepSlug.CUSTOMER_BANK,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const bankData = (task.variables.customer as Person).bankverbindungen;
    return isEqualIngoringOrder(bankData, customer.bankverbindungen);
  }
};

export const CUSTOMER_IDENTITY: Step = {
  title: 'Legitimation',
  slug: StepSlug.CUSTOMER_IDENTITY,
  canSkip: (task, customer) => {
    if (task.formKey !== TaskDefinition.FollowUpBackOffice || !customer) {
      return false;
    }
    const data = (task.variables.customer as Person).identifikationsmerkmale;
    // remove empty 'dokument' because that is a new feature
    data.forEach(i => isNil(i.dokument) ? delete i.dokument : '');
    return isEqualIngoringOrder(data, customer.identifikationsmerkmale);
  }
};

export const CUSTOMER_CONTRACT: Step = {
  title: 'Vertragsdaten',
  slug: StepSlug.CUSTOMER_CONTRACT,
  canSkip: () => {
    return false;
  }
};

export const CUSTOMER_CONTRACT_SINGLE: Step = {
  title: 'Vertrag',
  slug: StepSlug.CUSTOMER_CONTRACT_SINGLE,
  canSkip: () => {
    return false;
  }
};

export const DOCUMENTS_OVERVIEW: Step = {
  title: 'Dokumente',
  slug: StepSlug.DOCUMENTS_OVERVIEW,
  canSkip: (task) => {
    if (task.formKey === TaskDefinition.FollowUpAgent) {
      return false;
    }
    const customer = (task.variables.customer as Person);
    const contractId = task.variables.contractId;
    if (isNil(contractId)) {
      return false;
    }
    const documents = customer.dokumente ? customer.dokumente.filter(doc => doc.typ !== 'mvt') : customer.dokumente;
    const contractDocuments = customer.vertraege.find(c => c.id === contractId).dokumente;
    return (!documents || documents.length === 0) && contractDocuments.length === 0;
  }
};

export const VALID_STEPS = [
  CUSTOMER_BASIC,
  CUSTOMER_MARITAL_STATUS,
  CUSTOMER_CONTACT,
  CUSTOMER_ADDRESSES,
  CUSTOMER_SURPLUS,
  CUSTOMER_BANK,
  CUSTOMER_IDENTITY,
  CUSTOMER_RELATIONSHIP,
  CUSTOMER_EMPLOYMENT,
  CUSTOMER_TAX_INFOS,
  CUSTOMER_CONTRACT,
  CUSTOMER_CONTRACT_SINGLE,
  BROKER_CONTRACT,
  CUSTOMER_REWORK,
  DOCUMENTS_OVERVIEW
];

export const FOLLOW_UP_AGENT_STEPS = [
  CUSTOMER_BASIC,
  CUSTOMER_MARITAL_STATUS,
  CUSTOMER_CONTACT,
  CUSTOMER_ADDRESSES,
  CUSTOMER_SURPLUS,
  CUSTOMER_BANK,
  CUSTOMER_IDENTITY,
  CUSTOMER_RELATIONSHIP,
  CUSTOMER_EMPLOYMENT,
  CUSTOMER_TAX_INFOS,
  CUSTOMER_CONTRACT,
  BROKER_CONTRACT,
  DOCUMENTS_OVERVIEW];

export const FOLLOW_UP_BACK_OFFICE_STEPS = [
  CUSTOMER_BASIC,
  CUSTOMER_CONTACT,
  CUSTOMER_ADDRESSES,
  CUSTOMER_SURPLUS,
  CUSTOMER_BANK,
  CUSTOMER_RELATIONSHIP,
  CUSTOMER_CONTRACT_SINGLE,
  BROKER_CONTRACT,
  DOCUMENTS_OVERVIEW,
  CUSTOMER_REWORK];
