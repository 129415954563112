<app-data-comparison-card title="Bankverbindung" [crmEntity]="isFromServer" [primary]="isPrimary">
  <div class="flex flex-column gap-2"
       [formGroup]="control"
       tr-data-comparison-card-content>

    <div class="flex gap-2">
      <mat-checkbox formControlName="privat" class="w-6">
        Privat
      </mat-checkbox>
      <mat-checkbox formControlName="geschaeftlich" class="w-6">
        Geschäftlich
      </mat-checkbox>
      <mat-checkbox class="flex" (change)="setCardAsPrimary()" [disabled]="isPrimary" [checked]="isPrimary">
        Hauptbankverbindung
      </mat-checkbox>
    </div>
    <div>
      <div class="flex flex-column gap-3 warning-hint-color p-3 taures-border-radius"
           *ngIf="showAenderungsauftrag() && !hasAenderungsauftrag() && isHauptbankverbindung()">
        <div class="flex align-items-center">
          <mat-icon class="material-icons-outlined mr-2">warning_amber</mat-icon>
          <h3 class="mb-0 font-medium text-base">Warnung</h3>
        </div>
        {{ showAenderungsauftragWarningMessage() }}
      </div>
      <tr-info-box class="mb-0"
                   *ngIf="showAenderungsauftrag() && hasAenderungsauftrag()">
        <p *ngIf="showAenderungsauftragInfoMessage()">
          Ein Änderungsauftrag wurde für alle vom Vertragsservice bearbeitbaren Verträge vorgemerkt und wird <b>nach
          Abschließen des Vorgangs</b> ausgeführt.
        </p>
        <p *ngIf="!showAenderungsauftragInfoMessage()">
          Ein Änderungsauftrag wurde für ausgewählte Verträge vorgemerkt und wird <b>nach Abschließen des Vorgangs</b>
          ausgeführt.
        </p>
      </tr-info-box>
    </div>
    <div class="flex justify-content-end my-2">
      <button type="button" class="taures-primary-blue" mat-flat-button (click)="showAenderungsauftagDialog()"
              matTooltip="Änderungsauftrag erstellen"
              *ngIf="showAenderungsauftrag() && !hasAenderungsauftrag() && isHauptbankverbindung()">
        Änderungsauftrag erstellen
        <mat-icon class="material-icons-outlined icon-size" iconPositionEnd>add</mat-icon>
      </button>
      <button type="button" class="taures-primary-blue" mat-flat-button (click)="showAenderungsauftagDialog()"
              matTooltip="Änderungsauftrag bearbeiten"
              *ngIf="showAenderungsauftrag() && hasAenderungsauftrag() && isHauptbankverbindung()">
        Änderungsauftrag bearbeiten
        <mat-icon class="material-icons-outlined icon-size" iconPositionEnd>edit</mat-icon>
      </button>
    </div>

    <div class="flex gap2">
      <div *ngIf="kontoInhaber.length > 0" class="flex flex-column">
        <div class="flex-auto">
          Kontoinhaber:
        </div>
        <mat-chip-set class="flex-auto">
          <mat-chip *ngFor="let inhaberId of kontoInhaber.value; let i=index" class="chip"
                    matTooltip="{{inhaberId | personName: true | async}} entfernen">
            {{ inhaberId | personName: true | async }}
            <mat-icon matChipRemove (click)="removeInhaber(i)">cancel</mat-icon>
          </mat-chip>
        </mat-chip-set>
      </div>
      <div *ngIf="kontoInhaber.length === 0" class="flex-auto invalid">
        <i>Kein Kontoinhaber</i>
      </div>
    </div>

    <div class="flex gap-2">
      <tr-user-autocomplete placeholder="Kontoinhaber suchen"
                            class="flex-auto autocomplete-field"
                            [mode]="'customer'"
                            [formControl]="kontoInhaberIdControl">
      </tr-user-autocomplete>
      <app-create-customer (newCustomer)="onNewCustomer($event)"></app-create-customer>
    </div>

    <mat-form-field>
      <mat-label>IBAN</mat-label>
      <app-iban formControlName="iban" required></app-iban>
      <mat-error *ngIf="control.get('iban').hasError('iban')">Keine gültige IBAN</mat-error>
    </mat-form-field>

    <div class="flex gap-2">
      <mat-form-field class="flex-auto">
        <mat-label>BIC</mat-label>
        <input matInput formControlName="bic" appTrim>
        <mat-error *ngIf="control.get('bic').hasError('pattern')">Keine gültige BIC</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-auto">
        <mat-label>Bank</mat-label>
        <input matInput formControlName="bank" appTrim>
      </mat-form-field>
    </div>

    <div class="flex gap-2" *ngIf="isFromServer">
      <mat-form-field class="flex-auto">
        <mat-label>Konto</mat-label>
        <input matInput formControlName="konto" appTrim>
        <mat-error *ngIf="control.get('konto').hasError('pattern')">Keine gültige Kontonummber</mat-error>
      </mat-form-field>

      <mat-form-field class="flex-auto" *ngIf="isFromServer">
        <mat-label>Bankleitzahl</mat-label>
        <input matInput formControlName="blz" appTrim>
        <mat-error *ngIf="control.get('blz').hasError('pattern')">Keine gültige Bankleitzahl</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>Bemerkung</mat-label>
      <textarea matInput
                cdkTextareaAutosize
                [cdkAutosizeMinRows]="isFromServer ? 5 : 10"
                cdkAutosizeMaxRows="10"
                formControlName="bemerkung"
                class="bank-comments"
                appTrim>
      </textarea>
    </mat-form-field>

    <div class="flex gap-2">
      <mat-form-field class="flex-auto">
        <mat-label>Gültig ab</mat-label>
        <input matInput [matDatepicker]="fromPicker" formControlName="gueltigAb" required>
        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
        <mat-datepicker #fromPicker [disabled]="control.controls['gueltigAb'].disabled"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <mat-label>Gültig bis</mat-label>
        <input matInput [matDatepicker]="unitllPicker" formControlName="gueltigBis">
        <mat-datepicker-toggle matSuffix [for]="unitllPicker"></mat-datepicker-toggle>
        <mat-datepicker #unitllPicker [disabled]="control.controls['gueltigBis'].disabled"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="flex gap-3 justify-content-end align-items-center" tr-data-comparison-card-buttons>
    <button type="button" class="taures-blue-text-button" mat-button (click)="deleteCard()"
            matTooltip="Bankverbindung löschen">
      <mat-icon class="material-icons-outlined icon-size" iconPositionEnd>delete</mat-icon>
      Karte löschen
    </button>
  </div>
</app-data-comparison-card>
