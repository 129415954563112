import {StoreDevtoolsModule} from '@ngrx/store-devtools';

export const environment = {
  production: true,
  oauth: {
    issuer: 'ISSUER_URL',
    clientId: 'CLIENT_ID',
    scope: 'openid email',
    responseType: 'code',
    disablePKCE: true // this is not recommended, but otherwise, the keycloak.js adapter would not work
  },
  roleResourceId: 'ci_inbox-backend',
  crmBaseUrl: 'CRM_BASE_URL',
  kuemmerkastenUrl: 'KUEMMERKASTEN_URL',
  imports: [StoreDevtoolsModule.instrument({maxAge: 50, connectInZone: true})]
};
