import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {isNil} from 'lodash-es';
import {Address, Person, Relationship, RelationshipType, Status} from '../../../../services/person.service';

@Component({
  selector: 'app-single-relationship',
  templateUrl: 'single-relationship.component.html',
  styleUrls: ['single-relationship.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleRelationshipComponent implements OnChanges, OnDestroy {
  @Output() deleteRelationship: EventEmitter<void> = new EventEmitter();
  @Output() addToHousehold: EventEmitter<string> = new EventEmitter();
  @Output() removeFromHousehold: EventEmitter<string> = new EventEmitter();
  relationshipOptions: { label: string, value: { dbValue: string, from: boolean } }[];
  @Input()
  customerAddresses: Address[];
  @Input()
  customerId: string;
  @Input()
  isSameHousehold: boolean;
  user: UntypedFormControl;
  relType: UntypedFormControl;
  private destroy = new Subject<void>();

  constructor(readonly fb: UntypedFormBuilder, readonly changeDetector: ChangeDetectorRef) {
    this.user = new UntypedFormControl(null, Validators.required);
    this.relType = new UntypedFormControl(null, Validators.required);

    this.relType.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe((value: { dbValue: string, from: boolean }) => {
        const typ = this.theControl.get('typ');
        const quelle = this.theControl.get('quelle');
        const ziel = this.theControl.get('ziel');
        if (typ.value !== value.dbValue) {
          typ.setValue(value.dbValue);
        }
        if (value.from) {
          if (quelle.value !== this.user.value) {
            quelle.setValue(this.user.value);
          }
          if (ziel.value !== this.customerId) {
            ziel.setValue(this.customerId);
          }
        } else {
          if (ziel.value !== this.user.value) {
            ziel.setValue(this.user.value);
          }
          if (quelle.value !== this.customerId) {
            quelle.setValue(this.customerId);
          }
        }
        this.control.get('wirtschaftlichAbhaengig').setValue(typ.value === 'KIND' && !value.from);
        this.changeDetector.markForCheck();
      });

    this.user.valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        // set the value in the correct form field
        const type = this.relType.value;
        const quelle = this.theControl.get('quelle');
        const ziel = this.theControl.get('ziel');
        if (type && type.from) {
          if (quelle.value !== value) {
            quelle.setValue(value);
          }
          if (ziel.value !== this.customerId) {
            ziel.setValue(this.customerId);
          }
        } else {
          if (ziel.value !== value) {
            ziel.setValue(value);
          }
          if (quelle.value !== this.customerId) {
            quelle.setValue(this.customerId);
          }
        }
        this.changeDetector.markForCheck();
      });
  }

  private theControl: UntypedFormGroup;

  get control(): UntypedFormGroup {
    return this.theControl;
  }

  @Input()
  set control(value: UntypedFormGroup) {
    this.theControl = value;
    if (this.theControl.get('status') && this.isFromServer) {
      this.theControl.valueChanges
        .pipe(takeUntil(this.destroy))
        .subscribe((change) => {
          if (change.status !== Status.DELETED) {
            this.theControl.get('status').setValue(Status.MODIFIED, {emitEvent: false});
          }
        });
    }
  }

  private allRelationshipTypes: RelationshipType[];

  @Input()
  set relationshipTypes(value: RelationshipType[]) {
    this.allRelationshipTypes = value;
    // multiply out the uiValues since we actually have two values when
    // uivalue and uivalueVon are different
    this.relationshipOptions = this.allRelationshipTypes.reduce((prev, cur) => {
      prev.push({label: cur.uivalue, value: {dbValue: cur.dbvalue, from: false}});
      if (cur.uivalue !== cur.uivalueVon) {
        prev.push({label: cur.uivalueVon, value: {dbValue: cur.dbvalue, from: true}});
      }
      return prev;
    }, []);
  }

  get isFromServer(): boolean {
    return this.theControl && !!this.theControl.get('id').value;
  }

  public static buildGroup(fb: UntypedFormBuilder, relationship: Relationship, validation: boolean = true) {
    return fb.group({
      typ: [relationship.typ, validation ? [Validators.required] : []],
      ziel: [relationship.ziel, validation ? [Validators.required] : []],
      quelle: [relationship.quelle, validation ? [Validators.required] : []],
      id: relationship.id,
      status: relationship.status,
      erziehungsberechtigter: relationship.erziehungsberechtigter,
      wirtschaftlichAbhaengig: relationship.wirtschaftlichAbhaengig,
      kindergeldBis: relationship.kindergeldBis
    });
  }

  public static isRelationship(rel: Relationship, customerId: string): boolean {
    return rel.quelle === customerId;
  }

  public static isRelationshipFrom(rel: Relationship, customerId: string): boolean {
    return rel.ziel === customerId;
  }

  ngOnChanges() {
    if (this.customerId && this.theControl && this.allRelationshipTypes) {
      // get the initial value
      let userValue = null;
      let relTypeValue = null;
      const typDbValue = this.theControl.get('typ').value;
      const relationshipType = this.allRelationshipTypes.find(type => type.dbvalue === typDbValue);
      if (SingleRelationshipComponent.isRelationshipFrom(this.theControl.value, this.customerId)) {
        userValue = this.theControl.get('quelle').value;
        relTypeValue = {dbValue: typDbValue, from: relationshipType.uivalue !== relationshipType.uivalueVon};
      } else if (SingleRelationshipComponent.isRelationship(this.theControl.value, this.customerId)) {
        userValue = this.theControl.get('ziel').value;
        relTypeValue = {dbValue: typDbValue, from: false};
      }
      this.user.reset(userValue, {emitEvent: false});
      if (relTypeValue && relTypeValue.dbValue) {
        this.relType.reset(relTypeValue, {emitEvent: false});
      }
    }
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }

  deleteCard() {
    if (this.isFromServer) {
      this.theControl.get('status').setValue(Status.DELETED);
    } else {
      this.deleteRelationship.emit();
    }
  }

  onNewCustomer(customer: Person) {
    this.user.setValue(customer.id);
  }

  compareWith(o1: { dbValue: string, from: boolean }, o2: { dbValue: string, from: boolean }): boolean {
    return o1 && o2 && o1.dbValue === o2.dbValue && o1.from === o2.from;
  }

  changeSameHousehold(event: MatCheckboxChange) {
    const memberId = SingleRelationshipComponent.isRelationship(this.control.value, this.customerId) ?
      this.control.get('ziel').value : this.control.get('quelle').value;
    if (event.checked) {
      this.addToHousehold.emit(memberId);
    } else {
      this.removeFromHousehold.emit(memberId);
    }
  }

  disableSameHousehold() {
    return isNil(this.control.get('ziel').value) || isNil(this.control.get('quelle').value);
  }
}
