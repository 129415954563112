import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';
import {DocumentService} from '../../../../services/document.service';
import {DocumentFormComponent} from '../../../document-form/document-form.component';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ContractDocument, CustomerDocument} from '../../../../services/person.service';

@Component({
  selector: 'app-documents-dropzone',
  templateUrl: './documents-dropzone.component.html',
  styleUrls: ['./documents-dropzone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsDropzoneComponent implements OnDestroy {
  @Input() defaultType: string = null;
  @Input() documentArray: UntypedFormArray;
  @Input() validDocumentTypes: string[];
  @Output() fileDropped = new EventEmitter<ContractDocument[] | CustomerDocument[]>();
  @Output() addDocument = new EventEmitter<void>();
  uploadingFiles = new BehaviorSubject<boolean>(false);
  destroy = new Subject();

  constructor(readonly fb: UntypedFormBuilder, readonly documentService: DocumentService) {
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }

  onFilesDropped(files: FileList) {
    this.uploadingFiles.next(true);
    this.documentService.uploadAndGuessType(files)
      .pipe(takeUntil(this.destroy))
      .subscribe(docsAndTypes => {
        const documents: ContractDocument[] | CustomerDocument[] = docsAndTypes
          .map(docAndType => {
            let typ = docAndType.type;
            // if the type is not valid, take the first valid type as default
            if (this.validDocumentTypes
              && this.validDocumentTypes.length > 0
              && this.validDocumentTypes.indexOf(typ) === -1) {
              typ = this.defaultType;
            }
            return {
              filename: docAndType.file.name,
              typ,
              fileId: docAndType.doc.id
            };
          });
        documents.forEach(document => this.documentArray.push(DocumentFormComponent.buildGroup(this.fb, document)));
        this.uploadingFiles.next(false);
        this.fileDropped.emit(documents);
      });
  }

  onAdd() {
    this.documentArray.push(DocumentFormComponent.buildGroup(this.fb, {
      filename: null,
      typ: this.defaultType,
      fileId: null,
      currentProcess: true
    }));
    this.addDocument.emit();
  }
}
